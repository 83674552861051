import { Box, MenuItem, Select } from "@mui/material";

import React from "react";
import { useClinicIdMapping } from "src/pages/clinics/queries/useClinicIdMapping";
import { useDefaultClinicId } from "src/pages/profile/queries/useUser";
import { useUpdateUserMutation } from "src/pages/profile/mutations/useUpdateUserMutation";

const Navbar = () => {
  // const defaultClinicId = useDefaultClinicId();
  // const clinicIdMapping = useClinicIdMapping();
  // const clinicIdList = Object.keys(clinicIdMapping);
  // const updateDefaultClinic = useUpdateUserMutation();

  // const handleDefaultClinicChange = async (clinicId: string) => {
  //   await updateDefaultClinic.mutateAsync({
  //     clinicId,
  //   });
  // };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        height: "50px",
        borderBottom: "0.5px solid rgb(231, 228, 228)",
        fontSize: "14px",
        color: "#555",
      }}
    >
      {/* <Select
        labelId="clinic-select-label"
        id="clinic-select"
        value={defaultClinicId || "ALL"}
        onChange={(event) =>
          handleDefaultClinicChange(event.target.value as string)
        }
        sx={{
          height: "50px",
          borderColor: "grey",
          width: "200px",
          marginBottom: "10px",
        }}
      >
        {clinicIdList.map((clinicId) => (
          <MenuItem value={clinicId}>
            {clinicIdMapping[clinicId].clinicName}
          </MenuItem>
        ))}
        <MenuItem value={"ALL"}>All Clinics</MenuItem>
      </Select> */}
    </Box>
  );
};

export default Navbar;
