import { Availability, OccurrenceType, Provider } from "src/libs/models";
import { Box, Button, FormControlLabel, Switch } from "@mui/material";

import React from "react";

interface Props {
  availability: Availability;
  setProviders: React.Dispatch<React.SetStateAction<Provider[]>>;
  providerId: string;
  setUpdatedProviderIds: React.Dispatch<React.SetStateAction<string[]>>;
  mapByOperatory?: boolean;
}

const AvailabilityBox = ({
  availability,
  setProviders,
  providerId,
  setUpdatedProviderIds,
  mapByOperatory,
}: Props) => {
  const renderOccurrenceUI = () => {
    switch (availability.occurrenceType) {
      case OccurrenceType.SPECIFIC_DATE:
        return (
          <Box>
            <strong>Specific Date:</strong> {availability.specificDate}
          </Box>
        );
      case OccurrenceType.RECURRENCE:
        return (
          <Box>
            <strong>Recurrence:</strong> Every {availability.recurrence.num}{" "}
            {availability.recurrence.unit}(s) from {availability.recurrence.ref}
          </Box>
        );
      case OccurrenceType.DAYS:
        return (
          <Box>
            <strong>Days:</strong> {availability.days.join(", ")}
          </Box>
        );
      default:
        return null;
    }
  };

  const handleDelete = () => {
    setProviders((prev: Provider[]) => {
      return prev.map((provider: Provider) => {
        if (provider.providerId !== providerId) {
          return provider;
        }
        return {
          ...provider,
          availabilities: provider.availabilities.filter(
            (a: Availability) =>
              a.availabilityId !== availability.availabilityId
          ),
        };
      });
    });
    setUpdatedProviderIds((prev: string[]) => [...prev, providerId]);
  };

  const handleToggleActive = (event) => {
    setProviders((prev: Provider[]) => {
      return prev.map((provider: Provider) => {
        if (provider.providerId !== providerId) {
          return provider;
        }
        return {
          ...provider,
          availabilities: provider.availabilities.map((a: Availability) => {
            if (a.availabilityId !== availability.availabilityId) {
              return a;
            }
            return { ...a, active: event.target.checked };
          }),
        };
      });
    });
    setUpdatedProviderIds((prev: string[]) => [...prev, providerId]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        p: 2,
        gap: 1,
        width: "260px",
        marginRight: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <strong>Begin Time:</strong> {availability.beginTime}
        </Box>
        <Box>
          <strong>End Time:</strong> {availability.endTime}
        </Box>
        {renderOccurrenceUI()}
        {mapByOperatory && (
          <Box>
            <strong>Operatory:</strong> {availability.operatoryName}
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <FormControlLabel
          control={
            <Switch
              checked={availability.active}
              onChange={handleToggleActive}
              size="small"
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "green", // This is the color when the switch is on
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "green", // This is the track color when the switch is on
                },
              }}
            />
          }
          label={"Active"}
        />
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={handleDelete}
        >
          Delete
        </Button>
      </Box>
    </Box>
  );
};

export default AvailabilityBox;
