import { Button, Step, StepLabel, Stepper } from "@mui/material";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Intro from "./Intro";
import Ready from "./Ready";
import Setup from "./Setup";
import { authedPost } from "src/libs/aws";
import { homePage } from "src/libs/constants";
import { useNavigate } from "react-router-dom";
import { useUser } from "src/context/userContext";

const Onboarding = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState("");
  const { refreshUser, user } = useUser();

  // useEffect(() => {
  //   if (!navigate || !refreshUser) return;

  //   (async () => {
  //     try {
  //       await authedPost("/user", { action: "create" });
  //     } catch (e) {
  //       console.error(e);
  //     }

  //     let invites = [];
  //     try {
  //       const res = await authedPost("/user", { action: "getInvites" });
  //       invites = res.invites;
  //     } catch (e) {
  //       console.error("Invite error: ", e);
  //     }

  //     if (invites.length > 0) {
  //       try {
  //         await authedPost("/user", {
  //           action: "acceptInvite",
  //           tenantId: invites[0].TenantId,
  //         });
  //       } catch (e) {
  //         console.error(e);
  //       }

  //       await refreshUser();
  //       navigate("/dashboard");
  //     }

  //   })();
  // }, [navigate, refreshUser]);

  const handleName = (name: string) => {
    setName(name);
  };

  const frames = [
    <Setup name={name} handleName={handleName} />,
    <Intro />,
    <Ready />,
  ];

  const createTenant = async () => {
    await authedPost("/tenant", { action: "create", name });
    await refreshUser();
  };

  useEffect(() => {
    if (user?.tenant?.tenantId) {
      navigate(homePage);
    }
  }, [user.tenant.tenantId, navigate, user.tenant]);

  const handleNext = async () => {
    if (activeStep === frames.length - 1) {
      await createTenant();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {new Array(3).fill(null).map((_, i) => (
            <Step key={i}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box>{frames[activeStep]}</Box>

      <Box sx={{ display: "flex", flexDirection: "row", pb: 4 }}>
        {activeStep !== 0 && (
          <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
        )}

        <Button onClick={handleNext}>
          {activeStep === frames.length - 1 ? "Finish" : "Next"}
        </Button>
      </Box>
    </Box>
  );
};

export default Onboarding;
