import { Navigate } from "react-router-dom";
import React from "react";
import { homePage } from "src/libs/constants";
import { useIsAdmin } from "src/libs/hooks";

const AdminProtectedRoute = ({ children }) => {
  const isAdmin = useIsAdmin();

  if (!isAdmin) {
    // Redirect to a home page
    return <Navigate to={homePage} />;
  }

  return children;
};

export default AdminProtectedRoute;
