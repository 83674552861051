import { Backdrop, CircularProgress } from "@mui/material";

import React from "react";

const Loading = ({ color = "#5696fc" }) => {
  return (
    <Backdrop sx={{ color, backgroundColor: "#ffffff" }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
