import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { authedPost } from "src/libs/aws";
import { useUser } from "../../context/userContext";

const ConfirmSignUp = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { confirmSignUp, createUser } = useUser();
  const { email, password } = state || {};
  const [code, setCode] = useState("");
  const [trigger, setTrigger] = useState(false);

  // TODO: hacky work around to get the user created, need to fix later
  useEffect(() => {
    if (!trigger) return;

    (async () => {
      try {
        await createUser(navigate);
      } catch (e) {
        console.error(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  if (!email || !password) {
    navigate("/");
  }

  const handleConfirmSignUp = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (!code) return;
    const msg1 = await confirmSignUp(email, password, code);
    if (msg1) {
      throw new Error(msg1);
    }

    setTrigger(true);
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{ height: "100vh", overflowY: "auto" }}
    >
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Check your email
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>
          {`We've sent a 6-digit confirmation code to
          ${email}. The code will expire after 5 minutes.`}
        </Typography>
        <Box
          component="form"
          onSubmit={handleConfirmSignUp}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="code"
            label="code"
            name="code"
            autoComplete="code"
            autoFocus
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Verify code
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ConfirmSignUp;
