import { Box } from "@mui/material";
import Datatable from "../../components/datatable/Datatable";
import React from "react";

const Plugins = () => {
  return (
    <Box>
      plugins
      <Datatable />
    </Box>
  );
};

export default Plugins;
