import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import Loading from "src/components/loading/Loading";
import { getCallbacks } from "src/pages/callbacks/queries/useCallbacks";
import { getClinics } from "src/pages/clinics/queries/useClinicsList";
import { getConversations } from "src/pages/conversations/queries/useConversations";
import { getMembersAndInvites } from "src/pages/members/queries";
import { getVoicemails } from "src/pages/voicemail/queries/useVoicemails";
import { useLocation } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTenantId } from "src/pages/tenant/queries";

const DataProvider = ({ children }) => {
  const location = useLocation().pathname.split("/")[1];
  const queryClient = useQueryClient();
  const tenantId = useTenantId();
  const [memberAndInviteStatus, setMemberAndInviteStatus] =
    useState("fetching");
  const [conversationStatus, setConversationStatus] = useState("fetching");
  const [clinicStatus, setClinicStatus] = useState("fetching");
  const [callbackStatus, setCallbackStatus] = useState("fetching");
  const [voicemailStatus, setVoicemailStatus] = useState("fetching");

  const dashboardStatus = [
    memberAndInviteStatus,
    conversationStatus,
    clinicStatus,
    callbackStatus,
    voicemailStatus,
  ].includes("fetching")
    ? "fetching"
    : "fetched";

  const statuses: { [location: string]: string | Error } = {
    members: memberAndInviteStatus,
    conversations: conversationStatus,
    clinics: clinicStatus,
    usage: conversationStatus && voicemailStatus,
    callbacks: callbackStatus,
    voicemails: voicemailStatus,
  };
  const status = statuses[location] || "fetched";

  //Fetch all document data and set necessary statuses
  useEffect(() => {
    (async () => {
      if (!tenantId || !queryClient) return;
      //Reset states when tenant changes
      setMemberAndInviteStatus("fetching");
      setConversationStatus("fetching");
      setClinicStatus("fetching");
      setCallbackStatus("fetching");
      setVoicemailStatus("fetching");

      //Set data
      const results = await Promise.allSettled([
        queryClient
          .fetchQuery(["clinics", tenantId], getClinics)
          .then(() => setClinicStatus("fetched")),
        queryClient
          .fetchQuery(["conversations", tenantId], getConversations)
          .then(() => setConversationStatus("fetched")),
        queryClient
          .fetchQuery(["membersAndInvites", tenantId], getMembersAndInvites)
          .then(() => setMemberAndInviteStatus("fetched")),
        queryClient
          .fetchQuery(["callbacks", tenantId], getCallbacks)
          .then(() => setCallbackStatus("fetched")),
        queryClient
          .fetchQuery(["voicemails", tenantId], getVoicemails)
          .then(() => setVoicemailStatus("fetched")),
      ]);

      if (results[0].status === "rejected") setClinicStatus(results[0].reason);
      if (results[1].status === "rejected")
        setConversationStatus(results[1].reason);
      if (results[2].status === "rejected")
        setMemberAndInviteStatus(results[2].reason);
      if (results[3].status === "rejected")
        setCallbackStatus(results[3].reason);
      if (results[4].status === "rejected")
        setVoicemailStatus(results[4].reason);
    })();
  }, [queryClient, tenantId]);

  return (
    <Box>
      {status === "fetching" ? (
        <Loading color="green" />
      ) : status !== "fetched" ? (
        <Box> error </Box>
      ) : (
        children
      )}
    </Box>
  );
};

export default DataProvider;
