import { QueryFunction, useIsMutating, useQuery } from "react-query";

import { Provider } from "src/libs/models";
import { authedPost } from "src/libs/aws";

type QueryKey = ["providers", string];

export type RefreshReturn = {
  providers: Provider[];
};

export const getProviders: QueryFunction<RefreshReturn, QueryKey> = async ({
  queryKey,
}) => {
  const [, clinicId] = queryKey;
  if (!clinicId) {
    throw new Error("Missing clinicId");
  }
  const { providers } = await authedPost("/providers", {
    action: "get",
    clinicId,
  });

  return { providers };
};

export const useSelect = <T>(
  select: (data: RefreshReturn) => T,
  clinicId: string,
  options?: { staleTime?: number }
) => {
  const isMutating: boolean = useIsMutating(["providers", clinicId]) > 0;

  return useQuery(["providers", clinicId], getProviders, {
    select,
    initialData: { providers: [] },
    enabled: !!clinicId && !isMutating,
    refetchOnMount: true,
    ...options,
  });
};

export const useProviderList = (clinicId: string) =>
  useSelect((data) => data.providers, clinicId).data;
