import { prodHostnames, stagingHostnames } from "./libs/constants";

import awsConfigLocal from "./aws-config.local.json";
import awsConfigProd from "./aws-config.prod.json";
import awsConfigStaging from "./aws-config.staging.json";

// Temporary fix to select which aws config is selected
const hostname = window.location.hostname;
const cdk = prodHostnames.includes(hostname)
  ? awsConfigProd.CdkStack
  : stagingHostnames.includes(hostname)
  ? awsConfigStaging.CdkStack
  : awsConfigLocal.CdkStack;

const config = {
  region: cdk.region,
  userPoolId: cdk.userPoolId,
  identityPoolId: cdk.identityPoolId,
  userPoolWebClientId: cdk.userPoolWebClientId,
  endpoint: cdk.endpoint,
  testUserPassword: cdk.testUserPassword,
};

export default config;
