import { Invite, Role } from "src/libs/models";

import { RefreshReturn } from "../queries";
import { authedPost } from "src/libs/aws";
import { produce } from "immer";
import { useOptimisticUpdate } from "src/libs/query";
import { useTenantId } from "src/pages/tenant/queries";

interface UpdateRoleProps {
  memberId: string;
  role: Role;
}

const updateRoleAction =
  (tenantId: string) =>
  async ({ memberId, role }: UpdateRoleProps): Promise<any> => {
    return authedPost("/tenant", {
      action: "updateRole",
      tenantId,
      memberId,
      role,
    });
  };

export const useUpdateRoleMutation = () => {
  const tenantId: string = useTenantId();

  return useOptimisticUpdate(
    ["membersAndInvites", tenantId],
    updateRoleAction(tenantId),
    ({ memberId, role }) =>
      produce((draftMembersAndInvites: RefreshReturn) => {
        const member = draftMembersAndInvites.members.find(
          (member) => member.id === memberId
        );
        if (member) {
          member.role = role;
        }
      }),
    {
      retry: 3,
      mutationKey: ["membersAndInvites", "updateRole", tenantId],
    }
  );
};
