import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";

import { useUpdateClinicMutation } from "./mutations/useUpdateClinicMutation";

const ClinicsInfo = ({ clinicInfo, clinicId, setExpanded }) => {
  const [clinicName, setClinicName] = useState(clinicInfo.name);
  const [clinicAddress, setClinicAddress] = useState(clinicInfo.address);
  const [clinicManager, setClinicManager] = useState(clinicInfo.manager);
  const UpdateClinicMutation = useUpdateClinicMutation();

  const updateClinic = async (): Promise<void> => {
    await UpdateClinicMutation.mutateAsync({
      clinicId,
      clinicName,
      clinicAddress,
      clinicManager,
    });
  };

  const handleUpdateClinic = () => {
    updateClinic();
    setExpanded((prev: number) => prev + 1);
  };

  return (
    <Box>
      <TextField
        autoFocus
        margin="dense"
        id="clinicName"
        label="Clinic Name"
        type="text"
        fullWidth
        value={clinicName}
        onChange={(e) => setClinicName(e.target.value)}
      />
      <TextField
        autoFocus
        margin="dense"
        id="clinicLocation"
        label="Clinic Location"
        type="text"
        fullWidth
        value={clinicAddress}
        onChange={(e) => setClinicAddress(e.target.value)}
      />
      <TextField
        autoFocus
        margin="dense"
        id="officeManager"
        label="Clinic Office Manager"
        type="text"
        fullWidth
        value={clinicManager}
        onChange={(e) => setClinicManager(e.target.value)}
      />
      <Box
        sx={{ display: "flex", justifyContent: "flex-end", marginTop: "5px" }}
      >
        <Button onClick={handleUpdateClinic} variant="contained">
          Save Clinic Info
        </Button>
      </Box>
    </Box>
  );
};

export default ClinicsInfo;
