import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";

import { ClinicStatus } from "src/libs/models";
import { useUpdateClinicMutation } from "./mutations/useUpdateClinicMutation";

const PMSIntegration = ({
  pmsIntegrationDetails,
  clinicId,
  setExpanded,
  status,
}) => {
  const [subdomain, setSubdomain] = useState(pmsIntegrationDetails.subdomain);
  const [locationId, setLocationId] = useState(
    pmsIntegrationDetails.locationId
  );

  const hasPMSIntegrationFieldsChanged = (
    subdomain: string,
    locationId: string
  ) => {
    return (
      subdomain !== pmsIntegrationDetails.subdomain ||
      locationId !== pmsIntegrationDetails.locationId
    );
  };

  const UpdateClinicMutation = useUpdateClinicMutation();

  const updateClinic = async (): Promise<void> => {
    await UpdateClinicMutation.mutateAsync({
      clinicId,
      subdomain,
      locationId,
      ...(hasPMSIntegrationFieldsChanged(subdomain, locationId)
        ? { pmsConnected: false }
        : {}),
    });
  };

  const handleUpdateClinic = () => {
    updateClinic();
    setExpanded((prev: number) => prev + 1);
  };

  return (
    <Box>
      <TextField
        autoFocus
        margin="dense"
        id="subdomain"
        label="Subdomain"
        type="text"
        fullWidth
        value={subdomain}
        disabled={status === ClinicStatus.ACTIVE}
        onChange={(e) => setSubdomain(e.target.value)}
      />
      <TextField
        autoFocus
        margin="dense"
        id="locationId"
        label="Location ID"
        type="text"
        fullWidth
        value={locationId}
        disabled={status === ClinicStatus.ACTIVE}
        onChange={(e) => setLocationId(e.target.value)}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "5px",
        }}
      >
        <Button
          onClick={handleUpdateClinic}
          variant="contained"
          disabled={status === ClinicStatus.ACTIVE}
        >
          Save PMS Integration Details
        </Button>
      </Box>
    </Box>
  );
};

export default PMSIntegration;
