import { useMutation, useQueryClient } from "react-query";

import { authedPost } from "src/libs/aws";
import { useTenantId } from "src/pages/tenant/queries";

interface TestPhoneNumberConnectionProps {
  clinicId: string;
  baseClinicNumber: string;
}

const testPhoneNumberConnectionAction =
  (tenantId: string) =>
  async ({
    clinicId,
    baseClinicNumber,
  }: TestPhoneNumberConnectionProps): Promise<any> => {
    return authedPost("/clinics", {
      action: "testPhoneNumberConnection",
      tenantId,
      clinicId,
      baseClinicNumber,
    });
  };

// TODO: possibly need to invalidate cache after call succeeds
export const useTestPhoneNumberConnectionMutation = () => {
  const tenantId = useTenantId();
  const queryClient = useQueryClient();

  return useMutation(testPhoneNumberConnectionAction(tenantId), {
    onSettled: () => {
      queryClient.invalidateQueries(["clinics", tenantId]);
    },
    retry: 3,
    mutationKey: ["clinics", "testPhoneNumberConnection", tenantId],
  });
};
