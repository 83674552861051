import { Box } from "@mui/material";
import React from "react";

interface Props {
  text: string;
  sx?: any;
  removeBorder?: boolean;
}

const EmptyState: React.FunctionComponent<Props> = ({
  text,
  sx,
  removeBorder,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
        width: "90%",
        border: removeBorder ? "none" : "0.5px dashed grey",
        margin: "auto",
        ...sx,
      }}
    >
      <Box sx={{ color: "grey" }}>{text}</Box>
    </Box>
  );
};

export default EmptyState;
