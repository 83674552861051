import { Invite, Role } from "src/libs/models";

import { Member } from "src/libs/models";
import { RefreshReturn } from "../queries";
import { authedPost } from "src/libs/aws";
import { produce } from "immer";
import { useOptimisticUpdate } from "src/libs/query";
import { useTenantId } from "src/pages/tenant/queries";

interface InviteMemberProps {
  email: string;
  role: Role;
}

const inviteMemberAction =
  (tenantId: string) =>
  async ({ email, role }: InviteMemberProps): Promise<any> => {
    return authedPost("/tenant", {
      action: "invite",
      tenantId,
      email,
      role,
    });
  };

export const useInviteMemberMutation = () => {
  const tenantId: string = useTenantId();

  return useOptimisticUpdate(
    ["membersAndInvites", tenantId],
    inviteMemberAction(tenantId),
    ({ email, role }) =>
      produce((draftMembersAndInvites: RefreshReturn) => {
        draftMembersAndInvites.invites.push({ email, role });
      }),
    {
      retry: 3,
      mutationKey: ["membersAndInvites", "InviteMember", tenantId],
    }
  );
};
