import React, { useCallback, useEffect, useState } from "react";
import { Role, User, defaultUser } from "src/libs/models";

import { Auth } from "aws-amplify";
import { authedPost } from "src/libs/aws";
import { enqueueSnackbar } from "notistack";
import { homePage } from "src/libs/constants";
import { useMember } from "src/pages/members/queries/useMember";

type ContextProps = {
  cognitoUser: any;
  user: User;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  signIn: (email: string, password: string) => Promise<string>;
  signUp: (name: string, email: string, password: string) => Promise<string>;
  signOut: () => Promise<string>;
  confirmSignUp: (
    email: string,
    password: string,
    code: string
  ) => Promise<string>;
  refreshUser: () => Promise<void>;
  createUser: (navigate: any) => Promise<void>;
  sendForgotPasswordVerificationCode: (email: string) => Promise<string>;
  resetPassword: (
    email: string,
    code: string,
    newPassword: string
  ) => Promise<string>;
};

const UserContext = React.createContext<ContextProps | null>(null);

interface Props {
  children: React.ReactNode;
}

export const UserContextProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User>(defaultUser); // TODO: move to react query
  const [cognitoUser, setCognitoUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {}, [cognitoUser]);

  useEffect(() => {
    (async () => {
      try {
        await Auth.currentSession();
        const currUser = await Auth.currentAuthenticatedUser();
        setCognitoUser(currUser);
      } catch (e) {
        setLoading(false);
      }
    })();
  }, []);

  const refreshUser = async () => {
    if (cognitoUser) {
      try {
        const user = await authedPost("/user", { action: "get" });
        setUser(user);
      } catch (e) {
        console.error("error: ", e.message);
        setUser(defaultUser);
      }

      setLoading(false);
    } else {
      setUser(defaultUser);
    }
  };

  useEffect(() => {
    refreshUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoUser]);

  const signIn = async (email: string, password: string): Promise<string> => {
    setLoading(true);

    try {
      const user = await Auth.signIn(email.toLowerCase().trim(), password);
      if (user.signInUserSession) {
        setCognitoUser(await Auth.currentAuthenticatedUser());
        return "";
      }
    } catch (e) {
      return e.message;
    }
  };

  const signUp = async (
    name: string,
    email: string,
    password: string
  ): Promise<string> => {
    setLoading(true);

    try {
      await Auth.signUp({
        username: email.toLowerCase().trim(),
        password: password,
        attributes: {
          name: name.trim(),
        },
      });
      return "";
    } catch (e) {
      return e.message;
    }
  };

  const confirmSignUp = async (
    email: string,
    password: string,
    code: string
  ): Promise<string> => {
    try {
      setLoading(true);
      await Auth.confirmSignUp(email.toLowerCase(), code);
      await Auth.signIn(email.toLowerCase(), password);
      const temp = await Auth.currentAuthenticatedUser();
      setCognitoUser(temp);
      return "";
    } catch (e) {
      return e.message;
    }
  };

  const sendForgotPasswordVerificationCode = async (
    email: string
  ): Promise<string> => {
    try {
      await Auth.forgotPassword(email);
      enqueueSnackbar("Verification code sent to email.", {
        variant: "success",
      });
      return "";
    } catch (err) {
      enqueueSnackbar(err.message || JSON.stringify(err), {
        variant: "error",
      });
      return err.message;
    }
  };

  const resetPassword = async (
    email: string,
    code: string,
    newPassword: string
  ): Promise<string> => {
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      enqueueSnackbar("Password changed successfully", {
        variant: "success",
      });
      return "";
    } catch (err) {
      enqueueSnackbar(err.message || JSON.stringify(err), {
        variant: "error",
      });
      return err.message;
    }
  };

  const createUser = async (navigate) => {
    try {
      await authedPost("/user", { action: "create" });
    } catch (e) {
      console.error(e);
    }

    let invites = [];
    try {
      const res = await authedPost("/user", { action: "getInvites" });
      invites = res.invites;
    } catch (e) {
      console.error("Invite error: ", e);
    }

    if (invites.length > 0) {
      try {
        await authedPost("/user", {
          action: "acceptInvite",
          tenantId: invites[0].TenantId,
        });
      } catch (e) {
        console.error(e);
      }

      await refreshUser();
      navigate(homePage);
    } else {
      navigate("/onboarding");
    }
  };

  const signOut = async (): Promise<string> => {
    await Auth.signOut();
    setCognitoUser(null);
    setUser(defaultUser);
    return "";
  };

  return (
    <UserContext.Provider
      value={{
        cognitoUser,
        user,
        loading,
        setLoading,
        signIn,
        signUp,
        signOut,
        confirmSignUp,
        refreshUser,
        createUser,
        sendForgotPasswordVerificationCode,
        resetPassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return React.useContext(UserContext);
};
