import { Avatar, Box, Paper, Typography } from "@mui/material";
import {
  Conversation,
  Message,
  MessageDirection,
  MessageType,
} from "src/libs/models";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChatMessage from "./ChatMessage";
import React from "react";
import { formatPhoneNumber } from "src/libs/misc";
import { useClinicIdMapping } from "../clinics/queries/useClinicIdMapping";
import { useConversation } from "./queries/useConversation";
import { useParams } from "react-router-dom";

const ConversationPage = () => {
  const { conversationId } = useParams();
  const clinicIdMapping: {
    [id: string]: { clinicName: string; timeZone: string };
  } = useClinicIdMapping();
  const conversation: Conversation = useConversation(conversationId);
  const messages: Message[] = conversation.messages.filter(
    (message: Message) =>
      message.type === MessageType.SMS_MESSAGE ||
      message.type === MessageType.MISSED_CALL
  );
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "90vh" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "0.5px solid rgb(230, 227, 227)",
          height: "10vh",
          maxHeight: "125px",
          paddingLeft: "25px",
          paddingRight: "25px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{
              bgcolor: "#c3c3c4", // Example colors: blue for current user, grey for others
            }}
          >
            <AccountCircleIcon sx={{ color: "white" }} />
          </Avatar>
          <Box sx={{ marginLeft: "10px" }}>
            {formatPhoneNumber(conversation.patientNumber)}
          </Box>
        </Box>
        <Box>{clinicIdMapping[conversation.clinicId].clinicName}</Box>
      </Box>
      <Box
        component={Paper}
        elevation={0}
        sx={{
          padding: 2,
          overflow: "auto",
          flexGrow: 1,
          margin: "auto",
          marginBottom: 2,
          maxHeight: "80vh", // Adjust based on AppBar height
          width: "100%",
          maxWidth: "800px", // Set a max-width for larger screens
          borderLeft: "0.5px solid rgb(230, 227, 227)",
          borderRight: "0.5px solid rgb(230, 227, 227)",
          borderBottom: "0.5px solid rgb(230, 227, 227)",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {messages.map((message: Message, index) => {
            return (
              <ChatMessage
                key={index}
                message={message}
                isCurrentUser={message.role === "assistant"}
                messageTime={message.timestamp}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationPage;
