import { Availability, Provider } from "src/libs/models";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AvailabilityBox from "../clinics/AvailabilityBox";
import EmptyState from "src/components/EmptyState";
import ScrollableTableCell from "src/components/ScrollableTableCell";
import TablePagination from "@mui/material/TablePagination";
import { calculateTimeAgo } from "src/libs/misc";
import { durationOptions } from "src/libs/nexhealthModels";

const ProviderList = ({
  providers,
  handleAcceptingNewPatientsToggle,
  handleServiceChange,
  handleDurationChange,
  setSelectedProvider,
  setCreateProviderAvailability,
  setProviders,
  setUpdatedProviderIds,
  pmsIntegrationDetails,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const sortedProviders = [...providers].sort((a: Provider, b: Provider) => {
    const dateA = new Date(a.editTimestamp);
    const dateB = new Date(b.editTimestamp);

    return dateB.getTime() - dateA.getTime();
  });

  console.log("sortedProviders", sortedProviders);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {sortedProviders
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((provider: Provider) => (
          <>
            <TableRow key={provider.providerId}>
              <TableCell
                sx={{
                  width: "20%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box>{`${provider.firstName} ${provider.lastName}`}</Box>

                  <Typography sx={{ fontSize: "13px" }}>
                    (Last updated {calculateTimeAgo(provider.editTimestamp)})
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    ----------------------------
                  </Box>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small" // Makes the switch smaller
                        checked={provider.acceptingNewPatients}
                        onChange={() =>
                          handleAcceptingNewPatientsToggle(provider.providerId)
                        }
                        sx={{
                          //   "& .MuiSwitch-switchBase": {
                          //     color: "orange", // This is the color when the switch is off
                          //   },
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "green", // This is the color when the switch is on
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: "green", // This is the track color when the switch is on
                            },
                          //   "& .MuiSwitch-track": {
                          //     backgroundColor: "orange", // This is the track color when the switch is off
                          //   },
                        }}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Accepting New Patients
                      </Typography> // Smaller text for label
                    }
                  />
                </Box>
              </TableCell>
              <ScrollableTableCell>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {Object.keys(provider.serviceConfigurations).map(
                        (serviceKey) => (
                          <TableCell key={serviceKey}>
                            {serviceKey.replace(/_/g, " ")}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {Object.entries(provider.serviceConfigurations).map(
                        ([serviceKey, config]: [
                          string,
                          { enabled: boolean; duration: number }
                        ]) => (
                          <TableCell key={serviceKey}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    size="small"
                                    checked={config.enabled}
                                    onChange={() =>
                                      handleServiceChange(
                                        provider.providerId,
                                        serviceKey,
                                        !config.enabled
                                      )
                                    }
                                  />
                                }
                                label="Enabled"
                                sx={{
                                  marginBottom: "15px",
                                  marginTop: "5px",
                                  "& .MuiSwitch-switchBase.Mui-checked": {
                                    color: "green", // This is the color when the switch is on
                                  },
                                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                    {
                                      backgroundColor: "green", // This is the track color when the switch is on
                                    },
                                }}
                              />
                              <FormControl fullWidth>
                                <InputLabel id={`duration-label-${serviceKey}`}>
                                  Duration
                                </InputLabel>
                                <Select
                                  labelId={`duration-label-${serviceKey}`}
                                  id={`duration-${serviceKey}`}
                                  value={config.duration}
                                  label="Duration"
                                  onChange={(event) =>
                                    handleDurationChange(
                                      provider.providerId,
                                      serviceKey,
                                      event.target.value
                                    )
                                  }
                                  disabled={!config.enabled}
                                >
                                  {durationOptions.map((duration) => (
                                    <MenuItem
                                      key={duration.value}
                                      value={duration.value}
                                    >
                                      {duration.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </FormGroup>
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </ScrollableTableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    overflowX: "auto",
                  }}
                >
                  <IconButton
                    color="primary"
                    aria-label="filter list"
                    onClick={() => {
                      setSelectedProvider(provider.providerId);
                      setCreateProviderAvailability(true);
                    }}
                    size="small"
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>

                  {provider.availabilities?.length > 0 ? (
                    <ScrollableTableCell>
                      <Box sx={{ display: "flex" }}>
                        {provider.availabilities.map(
                          (availability: Availability) => (
                            <AvailabilityBox
                              availability={availability}
                              setProviders={setProviders}
                              providerId={provider.providerId}
                              setUpdatedProviderIds={setUpdatedProviderIds}
                              mapByOperatory={
                                pmsIntegrationDetails.mapByOperatory
                              }
                            />
                          )
                        )}
                      </Box>
                    </ScrollableTableCell>
                  ) : (
                    <EmptyState
                      text="No availabilities"
                      sx={{ height: "50px" }}
                      removeBorder
                    />
                  )}
                </Box>
              </TableCell>
            </TableRow>
          </>
        ))}
      <TableRow>
        <TableCell colSpan={2}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={providers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProviderList;
