import React, { useReducer } from "react";

interface State {
  darkMode: boolean;
}

export interface Action {
  type: string;
  payload?: any;
}

const DarkModeReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "LIGHT": {
      return {
        darkMode: false,
      };
    }
    case "DARK": {
      return {
        darkMode: true,
      };
    }
    case "TOGGLE": {
      return {
        darkMode: !state.darkMode,
      };
    }
    default:
      return state;
  }
};

type ContextProps = {
  darkMode: boolean;
  dispatch: React.Dispatch<Action>;
};

const INITIAL_STATE: ContextProps = {
  darkMode: false,
  dispatch: () => {},
};

const DarkModeContext = React.createContext<ContextProps>(INITIAL_STATE);

interface Props {
  children: React.ReactNode;
}

export const DarkModeContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(DarkModeReducer, INITIAL_STATE);

  return (
    <DarkModeContext.Provider value={{ darkMode: state.darkMode, dispatch }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export function useDarkMode() {
  return React.useContext(DarkModeContext);
}
