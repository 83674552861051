import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import { ClinicStatus } from "src/libs/models";
import React from "react";

interface Props {
  open: boolean;
  setOpen(open: boolean): any;
  updateStatus: () => Promise<void>;
}

const VerifyClinicDeactivationDialog: React.FC<Props> = ({
  open,
  setOpen,
  updateStatus,
}): JSX.Element => {
  const handleDeactivateClinic = async (): Promise<void> => {
    await updateStatus();
    setOpen(false);
  };

  const handleCloseDialog = (): void => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Confirm Deactivation"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Deactivating the clinic will pause all ongoing user conversations.
          Incoming messages will not be stored or processed when deactivated.
          This can potentially lose data. Are you sure you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: "flex" }}>
        <Button onClick={handleCloseDialog} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleDeactivateClinic} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VerifyClinicDeactivationDialog;
