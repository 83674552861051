import {
  Callback,
  CallbackState,
  ClinicStatus,
  Voicemail,
  VoicemailState,
} from "src/libs/models";

import { RefreshReturn } from "../queries/useVoicemails";
import { authedPost } from "src/libs/aws";
import { produce } from "immer";
import { useOptimisticUpdate } from "src/libs/query";
import { useTenantId } from "src/pages/tenant/queries";

interface UpdateVoicemailProps {
  voicemailId: string;
  state?: VoicemailState;
  notes?: string;
}

const updateVoicemailsAction =
  (tenantId: string) =>
  async ({ state, notes, voicemailId }: UpdateVoicemailProps): Promise<any> => {
    return authedPost("/voicemails", {
      action: "update",
      tenantId,
      voicemailId,
      state,
      notes,
    });
  };

export const useUpdateVoicemailsMutation = () => {
  const tenantId: string = useTenantId();

  return useOptimisticUpdate(
    ["voicemails", tenantId],
    updateVoicemailsAction(tenantId),
    ({ state, notes, voicemailId }) =>
      produce((draftVoicemails: RefreshReturn) => {
        const voicemail = draftVoicemails.voicemails.find(
          (voicemail: Voicemail) => voicemail.voicemailId === voicemailId
        );

        const timestamp = new Date().toISOString();
        if (voicemail) {
          voicemail.state = state || voicemail.state;
          voicemail.notes = notes || voicemail.notes;
          voicemail.editTimestamp = timestamp;
        }
      }),
    {
      retry: 3,
      mutationKey: ["voicemails", tenantId],
    }
  );
};
