import "@aws-amplify/ui-react/styles.css";

import { QueryClient, QueryClientProvider } from "react-query";

import { Amplify } from "aws-amplify";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import App from "./App";
import { DarkModeContextProvider } from "./context/darkModeContext";
import React from "react";
import ReactDOM from "react-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material";
import { UserContextProvider } from "./context/userContext";
import config from "./aws-config";
import { theme } from "./style/theme";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.region,
    userPoolId: config.userPoolId,
    identityPoolId: config.identityPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
    oauth: {},
  },
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: config.endpoint,
        region: config.region,
      },
    ],
  },
});

export const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    {/* TODO: not sure if AmplifyProvider is needed anymore, possibly remove */}
    <AmplifyProvider>
      <UserContextProvider>
        <DarkModeContextProvider>
          <SnackbarProvider maxSnack={3}>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </SnackbarProvider>
        </DarkModeContextProvider>
      </UserContextProvider>
    </AmplifyProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);
