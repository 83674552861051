import { Box } from "@mui/material";
import Datatable from "../../components/datatable/Datatable";
import React from "react";

const Policies = () => {
  return (
    <Box>
      Monitoring
      <Datatable />
    </Box>
  );
};

export default Policies;
