import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import Datatable from "../../components/datatable/Datatable";
import React from "react";
import { useClinicIdMapping } from "../clinics/queries/useClinicIdMapping";
import { useDefaultClinicId } from "./queries/useUser";
import { useUpdateUserMutation } from "./mutations/useUpdateUserMutation";

const Profile = () => {
  const defaultClinicId = useDefaultClinicId();
  const clinicIdMapping = useClinicIdMapping();
  const clinicIdList = Object.keys(clinicIdMapping);
  const updateDefaultClinic = useUpdateUserMutation();

  const handleDefaultClinicChange = async (clinicId: string) => {
    await updateDefaultClinic.mutateAsync({
      clinicId,
    });
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "200px",
          marginLeft: "25px",
          marginTop: "25px",
        }}
      >
        <FormControl fullWidth margin="normal">
          <InputLabel id="clinic-select-label">Filter Clinics</InputLabel>
          <Select
            labelId="clinic-select-label"
            id="clinic-select"
            value={defaultClinicId}
            onChange={(event) =>
              handleDefaultClinicChange(event.target.value as string)
            }
            sx={{ height: "30px", borderColor: "grey" }}
            label="Filter Clinics" // Add this prop to ensure the label moves correctly
          >
            {clinicIdList.map((clinicId) => (
              <MenuItem key={clinicId} value={clinicId}>
                {clinicIdMapping[clinicId].clinicName}
              </MenuItem>
            ))}
            <MenuItem value={"ALL"}>ALL</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Profile;
