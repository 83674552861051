import { Box } from "@mui/material";
import ConversationsTable from "./ConversationsTable";
import React from "react";

const ConversationsList = () => {
  return (
    <Box>
      <ConversationsTable />
    </Box>
  );
};

export default ConversationsList;
