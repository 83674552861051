import { Box, Button } from "@mui/material";
import React, { useState } from "react";

import ClinicsTable from "./ClinicsTable";
import CreateClinicDialog from "./CreateClinicDialog";
import { useIsAdmin } from "src/libs/hooks";

const ClinicsList = () => {
  const [createClinic, setCreateClinic] = useState<boolean>(false);
  const isAdmin = useIsAdmin();
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px",
          paddingRight: "20px",
        }}
      >
        {isAdmin && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setCreateClinic(true)}
          >
            Create clinic
          </Button>
        )}
      </Box>
      <ClinicsTable />
      <CreateClinicDialog open={createClinic} setOpen={setCreateClinic} />
    </Box>
  );
};

export default ClinicsList;
