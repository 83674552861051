import { Auth } from "@aws-amplify/auth";
import { RestAPI } from "@aws-amplify/api-rest";

async function token() {
  const user = await getCurrentUser();

  const token = user.signInUserSession.accessToken.jwtToken;
  return token;
}

export async function getCurrentUser() {
  return await Auth.currentAuthenticatedUser();
}

export async function unauthedPost(path: string, body: any = {}) {
  try {
    return await RestAPI.post("api", `${path}`, { body });
  } catch (e) {
    console.error(`API Failure: ${path}/${body?.api}`);
    console.error("Failed sending:", body);
    console.error("Message:", e?.response?.data?.msg);

    throw e;
  }
}

export async function authedPost(path: string, body: any = {}) {
  try {
    return await RestAPI.post("api", `${path}?accessToken=${await token()}`, {
      body,
    });
  } catch (e) {
    console.error(`API Failure: ${path}/${body?.api}`);
    console.error("Failed sending:", body);
    console.error("Message:", e?.response?.data?.msg);

    throw e;
  }
}
