import { Box, Button, Typography } from "@mui/material";
import { ClinicStatus, PhoneNumberConnectionStatus } from "src/libs/models";
import React, { useEffect, useState } from "react";

import { StatusIndicator } from "./StatusIndicator";
import { calculateTimeAgo } from "src/libs/misc";
import { useClinicsList } from "./queries/useClinicsList";
import { useTestPMSConnectionMutation } from "./mutations/useTestPMSConnectionMutation";
import { useTestPhoneNumberConnectionMutation } from "./mutations/useTestPhoneNumberConnectionMutation";

const PMSTesting = ({
  clinicId,
  clinicStatus,
  pmsIntegrationDetails,
  hasPMSIntegrationFields,
}) => {
  const [testingPMS, setTestingPMS] = useState(false);

  const testPMSConnectionMutation = useTestPMSConnectionMutation();

  const testPMS = async () => {
    setTestingPMS(true);
    await testPMSConnectionMutation.mutateAsync({
      clinicId,
      subdomain: pmsIntegrationDetails.subdomain,
      locationId: pmsIntegrationDetails.locationId,
    });
    setTestingPMS(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <StatusIndicator
          status={
            pmsIntegrationDetails.pmsConnected
              ? PhoneNumberConnectionStatus.CONNECTED
              : null
          }
          polling={testingPMS}
          type="PMS"
        />
        {pmsIntegrationDetails.lastTestedTimestamp && (
          <Typography sx={{ fontSize: "13px", marginLeft: "5px" }}>
            (Last tested{" "}
            {calculateTimeAgo(pmsIntegrationDetails.lastTestedTimestamp)})
          </Typography>
        )}
      </Box>
      <Button
        variant="contained"
        onClick={testPMS}
        disabled={
          testingPMS ||
          clinicStatus === ClinicStatus.ACTIVE ||
          !hasPMSIntegrationFields
        }
        sx={{
          width: "120px",
          p: 1,
          fontSize: "10px",
          marginRight: "5px",
          marginBottom: "5px",
        }}
      >
        {testingPMS ? "Testing..." : "Test Connection"}
      </Button>
    </Box>
  );
};

export default PMSTesting;
