import { Clinic, ClinicStatus } from "src/libs/models";
import { Link, useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import EmptyState from "src/components/EmptyState";
import Paper from "@mui/material/Paper";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useClinicsList } from "./queries/useClinicsList";

const ClinicsTable = () => {
  const navigate = useNavigate();
  const { data } = useClinicsList();
  const clinics: Clinic[] = data;

  if (clinics.length === 0) {
    return <EmptyState text="No clinics found" sx={{ marginTop: "20px" }} />;
  }

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Address</TableCell>
              <TableCell align="right">Office Manager</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clinics.map((clinic: Clinic) => (
              <TableRow
                key={clinic.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => navigate(`/clinics/${clinic.id}`)}
                hover
                style={{ cursor: "pointer" }}
              >
                <TableCell component="th" scope="row">
                  {clinic.info.name}
                </TableCell>
                <TableCell align="right">{clinic.info.address}</TableCell>
                <TableCell align="right">{clinic.info.manager}</TableCell>
                <TableCell
                  sx={{
                    color:
                      clinic.status === ClinicStatus.ACTIVE ? "green" : "red",
                  }}
                  align="right"
                >
                  {clinic.status}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ClinicsTable;
