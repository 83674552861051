import { Member } from "src/libs/models";
import { unknownMember } from "src/libs/models";
import { useSelect } from "../queries";

export const useMember = (userId: string) => {
  return useSelect((data) => {
    if (typeof userId !== "string") return unknownMember;
    const member = data.members.find((member: Member) => member.id === userId);
    return member || unknownMember;
  }).data;
};
