export function createId() {
  const options =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let id = "";
  for (let i = 0; i < 10; i++) {
    id += options[Math.floor(Math.random() * options.length)];
  }
  return id;
}

export const calculateTimeAgo = (timestamp: string) => {
  const now = new Date();
  const lastTestedDate = new Date(timestamp);
  const differenceInMilliseconds = Number(now) - Number(lastTestedDate);
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  if (differenceInDays >= 1) {
    return `${differenceInDays} day${differenceInDays === 1 ? "" : "s"} ago`;
  } else {
    const differenceInHours = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60)
    );
    if (differenceInHours >= 1) {
      // More than or equal to 1 hour but less than 1 day
      return `${differenceInHours} hour${
        differenceInHours === 1 ? "" : "s"
      } ago`;
    } else {
      // Less than 1 hour
      const differenceInMinutes = Math.floor(
        differenceInMilliseconds / (1000 * 60)
      );
      return `${differenceInMinutes} minute${
        differenceInMinutes === 1 ? "" : "s"
      } ago`;
    }
  }
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  // console.log(formatPhoneNumber("+12269172778"));  // Output: +1 (226) 917-2778
  // console.log(formatPhoneNumber("12269172778"));   // Output: +1 (226) 917-2778
  // console.log(formatPhoneNumber("2269172778"));    // Output: (226) 917-2778

  // Remove all non-digit characters from the phone number
  const digits = phoneNumber.replace(/\D/g, "");

  // Check if the phone number has the correct length of 10 digits
  if (digits.length === 10) {
    // Format the phone number to (XXX) XXX-XXXX using substring
    return `(${digits.substring(0, 3)}) ${digits.substring(
      3,
      6
    )}-${digits.substring(6)}`;
  } else if (digits.length === 11 && digits.startsWith("1")) {
    // Format 11-digit phone numbers that start with '1' as +1 (XXX) XXX-XXXX
    return `+1 (${digits.substring(1, 4)}) ${digits.substring(
      4,
      7
    )}-${digits.substring(7)}`;
  } else {
    // Return the original phone number if it doesn't match expected formats
    return phoneNumber;
  }
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const now = new Date();
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
  const startOfDay = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  ).getTime();
  const daysAgo = Math.round((startOfDay - date.getTime()) / oneDay);
  const isSameYear = date.getFullYear() === now.getFullYear();

  if (daysAgo < 1 && date > new Date(startOfDay)) {
    // Same day: Format with AM/PM
    const timeFormatter = new Intl.DateTimeFormat("default", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return timeFormatter.format(date);
  } else {
    // For other dates, use a different formatter without hour and minute
    const dateFormatter = new Intl.DateTimeFormat("default", {
      weekday: daysAgo < 7 && daysAgo >= 1 ? "long" : undefined,
      month: "long",
      day: "numeric",
      year: isSameYear ? undefined : "numeric",
    });
    return dateFormatter.format(date);
  }
};

export const truncateText = (text: string, maxLength = 50) => {
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};

export const enumToObjectWithFixedValue = (
  enumObj: any,
  value: number
): any => {
  return Object.values(enumObj).reduce((obj: any, key: any) => {
    obj[key] = value;
    return obj;
  }, {} as { [key: string]: number });
};
