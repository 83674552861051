import { QueryFunction, useIsMutating, useQuery } from "react-query";

import { Conversation } from "src/libs/models";
import { authedPost } from "src/libs/aws";
import { useTenantId } from "../../tenant/queries";

type QueryKey = ["conversations", string];

export type RefreshReturn = {
  conversations: Conversation[];
};

export const getConversations: QueryFunction<RefreshReturn, QueryKey> = async ({
  queryKey,
}) => {
  const [, tenantId] = queryKey;
  if (!tenantId) {
    throw new Error("Missing tenantId");
  }
  const { conversations } = await authedPost("/conversations", {
    action: "get",
    tenantId,
  });

  return { conversations };
};

export const useSelect = <T>(
  select: (data: RefreshReturn) => T,
  options?: { staleTime?: number }
) => {
  const tenantId: string = useTenantId();
  const isMutating: boolean = useIsMutating(["conversations", tenantId]) > 0;

  return useQuery(["conversations", tenantId], getConversations, {
    select,
    initialData: { conversations: [] },
    enabled: !!tenantId && !isMutating,
    refetchOnMount: true,
    ...options,
  });
};

export const useConversations = () =>
  useSelect((data) => data.conversations).data;
