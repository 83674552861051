import {
  Callback,
  CallbackState,
  Role,
  Voicemail,
  VoicemailState,
} from "src/libs/models";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Icon } from "@mui/material";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import React from "react";
import StorageIcon from "@mui/icons-material/Storage";
import StoreIcon from "@mui/icons-material/Store";
import VoicemailIcon from "@mui/icons-material/Voicemail";
import { useCallbacks } from "src/pages/callbacks/queries/useCallbacks";
import { useRole } from "src/libs/hooks";
import { useUser } from "../../context/userContext";
import { useVoicemails } from "src/pages/voicemail/queries/useVoicemails";

const Sidebar = () => {
  const { signOut, user } = useUser();
  const userRole = useRole();
  const voicemails: Voicemail[] = useVoicemails() || [];
  const newVoicemailCount = voicemails.filter(
    (voicemail: Voicemail) => voicemail.state === VoicemailState.NEW
  ).length;

  const callbacks: Callback[] = useCallbacks() || [];
  const newCallbackCount = callbacks.filter(
    (callback: Callback) => callback.state === CallbackState.PENDING
  ).length;
  const sidebarItems = [
    {
      type: "title",
      label: "ANALYTICS",
      roles: [Role.Admin, Role.Collaborator],
    },
    {
      path: "/conversations",
      icon: StorageIcon,
      label: "Conversations",
      roles: [Role.Admin, Role.Collaborator],
    },
    {
      path: "/usage",
      icon: InsertChartIcon,
      label: "History",
      roles: [Role.Admin, Role.Collaborator],
    },
    {
      type: "title",
      label: "ACTIONS",
      roles: [Role.Admin, Role.Collaborator],
    },
    {
      path: "/callbacks",
      icon: PhoneCallbackIcon,
      label: "Callbacks",
      newCount: newCallbackCount,
      roles: [Role.Admin, Role.Collaborator],
    },
    {
      path: "/voicemails",
      icon: VoicemailIcon,
      label: "Voicemails",
      newCount: newVoicemailCount,
      roles: [Role.Admin, Role.Collaborator],
    },
    { type: "title", label: "MANAGE", roles: [Role.Admin, Role.Collaborator] },
    {
      path: "/clinics",
      icon: StoreIcon,
      label: "Clinics",
      roles: [Role.Admin, Role.Collaborator],
    },
    {
      path: "/members",
      icon: PersonOutlineIcon,
      label: "Members",
      roles: [Role.Admin, Role.Collaborator],
    },
    { type: "title", label: "USER", roles: [Role.Admin, Role.Collaborator] },
    {
      path: "/profile",
      icon: AccountBoxIcon,
      label: "Profile",
      roles: [Role.Admin, Role.Collaborator],
    },
    {
      path: "/signIn",
      icon: ExitToAppIcon,
      label: "Logout",
      roles: [Role.Admin, Role.Collaborator],
      action: "signOut",
    },
  ];

  const filteredItems = sidebarItems.filter((item) =>
    item.roles.includes(userRole)
  );

  return (
    <Box
      sx={{
        flex: 1,
        borderRight: "0.5px solid rgb(230, 227, 227)",
        minHeight: "100vh",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          height: 50,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#6439ff",
          }}
        >
          {user.tenant?.tenantName}
        </Box>
      </Box>
      <Divider sx={{ borderColor: "rgb(230, 227, 227)" }} />
      <Box sx={{ paddingLeft: "10px", paddingTop: "5px" }}>
        {filteredItems.map((item, index) => {
          if (item.type === "title") {
            return (
              <Box
                key={index}
                sx={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  color: "#999",
                  marginTop: "15px",
                  marginBottom: "5px",
                }}
              >
                {item.label}
              </Box>
            );
          } else {
            return (
              <ListItem
                key={index}
                component={Link}
                to={item.path}
                onClick={item.action ? signOut : null}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingY: "5px",
                  paddingX: "10px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#ece8ff",
                  },
                  textDecoration: "none",
                }}
              >
                <Box sx={{ minWidth: "30px", color: "#7451f8" }}>
                  <Icon component={item.icon} />
                </Box>
                {item.newCount !== undefined ? (
                  <Badge
                    badgeContent={item.newCount}
                    color="primary"
                    sx={{
                      ".MuiBadge-badge": {
                        right: -20, // Adjust this value as needed to position the badge more to the right
                        top: 9, // Adjust this value for vertical alignment if necessary
                        backgroundColor: "#893de0",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        color: "#888",
                        fontSize: "13px",
                        fontWeight: "500",
                      }}
                    >
                      {item.label}
                    </Box>
                  </Badge>
                ) : (
                  <Box
                    sx={{
                      color: "#888",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    {item.label}
                  </Box>
                )}
              </ListItem>
            );
          }
        })}
      </Box>
    </Box>
  );
};

export default Sidebar;
