import { Box, MenuItem, Select } from "@mui/material";
import { Invite, Role } from "src/libs/models";
import { useInvites, useMembers } from "./queries";

import { Member } from "src/libs/models";
import Paper from "@mui/material/Paper";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useIsAdmin } from "src/libs/hooks";
import { useUpdateRoleMutation } from "./mutations/useUpdateRoleMutation";
import { useUser } from "src/context/userContext";

const MembersTable = () => {
  const { user } = useUser();
  const members: Member[] = useMembers();
  const invitedUsers: Invite[] = useInvites();
  const isAdmin = useIsAdmin();
  const updateRoleMutation = useUpdateRoleMutation();
  const handleRoleChange = async (memberId: string, role: Role) => {
    await updateRoleMutation.mutateAsync({
      memberId,
      role,
    });
  };
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((member) => (
              <TableRow
                key={member.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  backgroundColor: member.id === user.id ? "#ededed" : "white",
                }}
              >
                <TableCell component="th" scope="row">
                  {member.id === user.id ? "You" : member.name}
                </TableCell>
                <TableCell align="right">{member.email}</TableCell>
                <TableCell align="right">
                  {member.id !== user.id && isAdmin ? (
                    <Select
                      labelId="role-select-label"
                      id="role-select"
                      value={member.role}
                      onChange={(event) =>
                        handleRoleChange(member.id, event.target.value as Role)
                      }
                      sx={{ height: "30px", borderColor: "grey" }}
                    >
                      <MenuItem value={Role.Admin}>Admin</MenuItem>
                      <MenuItem value={Role.Collaborator}>
                        Collaborator
                      </MenuItem>
                    </Select>
                  ) : (
                    member.role
                  )}
                </TableCell>
              </TableRow>
            ))}
            {invitedUsers.map((invitedUser) => (
              <TableRow
                key={invitedUser.email}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={{ color: "red" }} component="th" scope="row">
                  Pending Invitation
                </TableCell>
                <TableCell align="right">{invitedUser.email}</TableCell>
                <TableCell align="right">{invitedUser.role}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MembersTable;
