import { Box, Button, TextField, Typography } from "@mui/material";
import {
  ClinicStatus,
  PhoneNumberConnectionStatus,
  PhoneNumberIntegrationDetails,
} from "src/libs/models";
import React, { useState } from "react";

import { formatPhoneNumber } from "src/libs/misc";
import { useUpdateClinicMutation } from "./mutations/useUpdateClinicMutation";

interface PhoneNumberIntegrationProps {
  phoneNumberIntegrationDetails: PhoneNumberIntegrationDetails;
  setExpanded: React.Dispatch<React.SetStateAction<number>>;
  clinicId: string;
  forwardingClinicNumber: string;
  clinicStatus: ClinicStatus;
}

const PhoneNumberIntegration: React.FC<PhoneNumberIntegrationProps> = ({
  phoneNumberIntegrationDetails,
  setExpanded,
  clinicId,
  forwardingClinicNumber,
  clinicStatus,
}) => {
  const [baseClinicNumber, setCaseClinicNumber] = useState(
    phoneNumberIntegrationDetails.baseClinicNumber
  );

  const UpdateClinicMutation = useUpdateClinicMutation();

  const hasPhoneNumberIntegrationFieldsChanged = (
    baseClinicNumber: string
  ): boolean => {
    return baseClinicNumber !== phoneNumberIntegrationDetails.baseClinicNumber;
  };

  const updateClinic = async (): Promise<void> => {
    await UpdateClinicMutation.mutateAsync({
      clinicId,
      baseClinicNumber,
      ...(hasPhoneNumberIntegrationFieldsChanged(baseClinicNumber)
        ? { phoneNumberStatus: PhoneNumberConnectionStatus.NOT_CONNECTED }
        : {}),
    });
  };

  const handleUpdateClinic = () => {
    updateClinic();
    setExpanded((prev: number) => prev + 1);
  };

  const isValidPhoneNumber = (number: string) => {
    const regex = /^\+\d{11}$/;
    return regex.test(number);
  };

  return (
    <Box>
      <Box>
        <Typography sx={{ fontSize: "12", fontWeight: "800" }}>
          Please contact your phone provider and set up missed call forwarding
          from your clinic phone number to the forwarding-number. Please do this
          after hours.
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          id="clinicNumber"
          label="Clinic Phone Number"
          type="text"
          placeholder="+19876543210"
          fullWidth
          value={baseClinicNumber}
          onChange={(e) => setCaseClinicNumber(e.target.value)}
          disabled={clinicStatus === ClinicStatus.ACTIVE}
        />
        <Typography sx={{ fontSize: "10" }}>{`forwarding-number: ${
          forwardingClinicNumber
            ? formatPhoneNumber(forwardingClinicNumber)
            : "Pending"
        }`}</Typography>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "flex-end", marginTop: "5px" }}
      >
        <Button
          onClick={handleUpdateClinic}
          variant="contained"
          disabled={
            clinicStatus === ClinicStatus.ACTIVE ||
            !isValidPhoneNumber(baseClinicNumber)
          }
        >
          Save Phone Number Integration Details
        </Button>
      </Box>
    </Box>
  );
};

export default PhoneNumberIntegration;
