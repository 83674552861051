import { Box, Button } from "@mui/material";
import React, { useState } from "react";

import InviteMemberDialog from "./InviteMemberDialog";
import MembersTable from "./MembersTable";
import { useIsAdmin } from "src/libs/hooks";

const MembersList = () => {
  const [openInvite, setOpenInvite] = useState<boolean>(false);
  const isAdmin = useIsAdmin();
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px",
          paddingRight: "20px",
        }}
      >
        {isAdmin && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenInvite(true)}
          >
            Invite new user
          </Button>
        )}
      </Box>
      <MembersTable />
      <InviteMemberDialog open={openInvite} setOpen={setOpenInvite} />
    </Box>
  );
};

export default MembersList;
