import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import { ClinicStatus } from "src/libs/models";
import React from "react";

interface Props {
  open: boolean;
  setOpen(open: boolean): any;
  updateStatus: () => Promise<void>;
}

const VerifyClinicActivationDialog: React.FC<Props> = ({
  open,
  setOpen,
  updateStatus,
}): JSX.Element => {
  const handleDeactivateClinic = async (): Promise<void> => {
    await updateStatus();
    setOpen(false);
  };

  const handleCloseDialog = (): void => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirm Activation"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Activating this clinic will capture all missed calls and resume all
          patient communications. Are you ready to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: "flex" }}>
        <Button onClick={handleCloseDialog} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleDeactivateClinic} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VerifyClinicActivationDialog;
