import { Box } from "@mui/material";
import React from "react";

const Audio = ({ src }) => {
  return (
    <Box>
      <audio controls src={src}>
        Your browser does not support the audio element.
      </audio>
    </Box>
  );
};

export default Audio;
