export enum AppointmentType {
  CONSULTATION_FOR_ORTHODONTICS = "CONSULTATION_FOR_ORTHODONTICS",
  CONSULTATION_FOR_EXTRACTIONS = "CONSULTATION_FOR_EXTRACTIONS",
  CONSULTATION_FOR_IMPLANTS = "CONSULTATION_FOR_IMPLANTS",
  CONSULTATION_FOR_DENTURES = "CONSULTATION_FOR_DENTURES",
  CONSULTATION_FOR_BRIDGE = "CONSULTATION_FOR_BRIDGE",
  CONSULTATION_FOR_GRAFTING = "CONSULTATION_FOR_GRAFTING",
  CONSULTATION_FOR_COSMETICS = "CONSULTATION_FOR_COSMETICS",
  EMERGENCY_TREATMENT = "EMERGENCY_TREATMENT",
  NEW_PATIENT_EXAM = "NEW_PATIENT_EXAM",
  CLEANING = "CLEANING",
}

export enum ServiceType {
  SCHEDULE_APPOINTMENT = "SCHEDULE_APPOINTMENT",
  CANCEL_APPOINTMENT = "CANCEL_APPOINTMENT",
  RESCHEDULE_APPOINTMENT = "RESCHEDULE_APPOINTMENT",
  SCHEDULE_CALLBACK = "SCHEDULE_CALLBACK",
}

export const durationOptions = [15, 30, 45, 60, 90, 120].map((item) => ({
  label: `${item} minutes`,
  value: item,
}));

export const defaultReceptionistName = "Rachel";

export interface PolicyMap {
  [ServiceType.SCHEDULE_APPOINTMENT]: boolean;
  [ServiceType.CANCEL_APPOINTMENT]: boolean;
  [ServiceType.RESCHEDULE_APPOINTMENT]: boolean;
  [ServiceType.SCHEDULE_CALLBACK]: boolean;
}

export const defaultPolicyMap = {
  [ServiceType.SCHEDULE_APPOINTMENT]: false,
  [ServiceType.CANCEL_APPOINTMENT]: false,
  [ServiceType.RESCHEDULE_APPOINTMENT]: false,
  [ServiceType.SCHEDULE_CALLBACK]: false,
};
