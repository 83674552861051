import { Box } from "@mui/material";
import CallbacksTable from "./CallbacksTable";
import React from "react";

const Callbacks = () => {
  return (
    <Box>
      <CallbacksTable />
    </Box>
  );
};

export default Callbacks;
