import { Clinic, PhoneNumberConnectionStatus } from "src/libs/models";

import { PolicyMap } from "src/libs/nexhealthModels";
import { RefreshReturn } from "../queries/useClinicsList";
import { authedPost } from "src/libs/aws";
import { produce } from "immer";
import { useOptimisticUpdate } from "src/libs/query";
import { useTenantId } from "src/pages/tenant/queries";

interface UpdateClinicProps {
  clinicId: string;
  clinicName?: string;
  clinicAddress?: string;
  clinicManager?: string;
  subdomain?: string;
  locationId?: string;
  baseClinicNumber?: string;
  policyMap?: PolicyMap;
  receptionistName?: string;
  clinicNameCallGreeting?: string;
  pmsConnected?: boolean;
  phoneNumberStatus?: PhoneNumberConnectionStatus;
}

const updateClinicAction =
  (tenantId: string) =>
  async (props: UpdateClinicProps): Promise<any> => {
    return authedPost("/clinics", {
      action: "update",
      tenantId,
      ...props,
    });
  };

export const useUpdateClinicMutation = () => {
  const tenantId: string = useTenantId();

  return useOptimisticUpdate(
    ["clinics", tenantId],
    updateClinicAction(tenantId),
    ({
      clinicId,
      clinicName,
      clinicAddress,
      clinicManager,
      subdomain,
      locationId,
      baseClinicNumber,
      policyMap,
      receptionistName,
      clinicNameCallGreeting,
      pmsConnected,
      phoneNumberStatus,
    }) =>
      produce((draftClinics: RefreshReturn) => {
        if (draftClinics.clinics[clinicId] === undefined)
          throw new Error("Invalid clientId");

        const draftClinic: Clinic = draftClinics.clinics[clinicId];

        draftClinics.clinics[clinicId] = {
          ...draftClinic,
          info: {
            name: clinicName || draftClinic.info.name,
            address: clinicAddress || draftClinic.info.address,
            manager: clinicManager || draftClinic.info.manager,
          },
          pmsIntegrationDetails: {
            ...draftClinic.pmsIntegrationDetails,
            subdomain: subdomain || draftClinic.pmsIntegrationDetails.subdomain,
            locationId:
              locationId || draftClinic.pmsIntegrationDetails.locationId,
            pmsConnected:
              pmsConnected !== undefined
                ? pmsConnected
                : draftClinic.pmsIntegrationDetails.pmsConnected,
          },
          phoneNumberIntegrationDetails: {
            ...draftClinic.phoneNumberIntegrationDetails,
            baseClinicNumber:
              baseClinicNumber ||
              draftClinic.phoneNumberIntegrationDetails.baseClinicNumber,
            phoneNumberStatus:
              phoneNumberStatus ||
              draftClinic.phoneNumberIntegrationDetails.phoneNumberStatus,
          },

          preferences: {
            policyMap: policyMap || draftClinic.preferences.policyMap,
            callGreeting: {
              ...draftClinic.preferences.callGreeting,
              clinicName:
                clinicNameCallGreeting ||
                draftClinic.preferences.callGreeting.clinicName,
              receptionistName:
                receptionistName ||
                draftClinic.preferences.callGreeting.receptionistName,
            },
          },
        };
      }),
    {
      retry: 3,
      mutationKey: ["clinics", tenantId],
    }
  );
};
