import AdminProtectedRoute from "./AdminProtectedRoute";
import AuthenticationProvider from "./AuthenticationProvider";
import Billing from "../../pages/billing/Billing";
import Callbacks from "src/pages/callbacks/Callbacks";
import ClinicsList from "../../pages/clinics/ClinicsList";
import ClinicsPage from "src/pages/clinics/ClinicsPage";
import ConversationPage from "src/pages/conversations/ConversationPage";
import ConversationsList from "../../pages/conversations/ConversationsList";
import Dashboard from "../../pages/dashboard/Dashboard";
import DataProvider from "../dataProvider/DataProvider";
import Integrations from "../../pages/integrations/Integrations";
import MembersList from "../../pages/members/MembersList";
import Navigation from "../navigation/Navigation";
import Onboarding from "../../pages/onboarding/Onboarding";
import Plugins from "../../pages/plugins/Plugins";
import Policies from "../../pages/policies/Policies";
import Profile from "../../pages/profile/Profile";
import React from "react";
import Settings from "../../pages/settings/Settings";
import Usage from "../../pages/usage/Usage";
import Voicemails from "src/pages/voicemail/Voicemails";
import { useRoutes } from "react-router-dom";

const AuthenticatedRoutes = () => {
  // authenticated routes
  const mainPages = useRoutes([
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/conversations", element: <ConversationsList /> },
    { path: "/conversations/:conversationId", element: <ConversationPage /> },

    { path: "/usage", element: <Usage /> },
    { path: "/callbacks", element: <Callbacks /> },
    { path: "/voicemails", element: <Voicemails /> },

    {
      path: "/clinics",
      element: <ClinicsList />,
    },
    {
      path: "/clinics/:clinicId",
      element: <ClinicsPage />,
    },
    { path: "/policies", element: <Policies /> },
    { path: "/members", element: <MembersList /> },
    { path: "/plugins", element: <Plugins /> },
    { path: "/integrations", element: <Integrations /> },
    {
      path: "/billing",
      element: (
        <AdminProtectedRoute>
          <Billing />
        </AdminProtectedRoute>
      ),
    },
    {
      path: "/settings",
      element: (
        <AdminProtectedRoute>
          <Settings />
        </AdminProtectedRoute>
      ),
    },
    { path: "/profile", element: <Profile /> },
  ]);

  const miscPages = useRoutes([
    { path: "/onboarding", element: <Onboarding /> },
  ]);

  return (
    <AuthenticationProvider>
      {miscPages}

      <DataProvider>
        <Navigation>{mainPages}</Navigation>
      </DataProvider>
    </AuthenticationProvider>
  );
};

export default AuthenticatedRoutes;
