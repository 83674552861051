import Loading from "src/components/loading/Loading";
import { Navigate } from "react-router-dom";
import React from "react";
import { useUser } from "../../context/userContext";

const AuthenticationProvider = ({ children }) => {
  const { cognitoUser, loading } = useUser();

  if (loading) {
    return <Loading color={"#5696fc"} />;
  }

  if (!cognitoUser) {
    return <Navigate to="/signIn" />;
  }

  return children;
};

export default AuthenticationProvider;
