import { QueryFunction, useIsMutating, useQuery } from "react-query";
import { User, defaultUser } from "src/libs/models";

import { authedPost } from "src/libs/aws";

type QueryKey = ["users", string];

export type RefreshReturn = {
  user: User;
};

export const getUser: QueryFunction<RefreshReturn, QueryKey> = async () => {
  const user = await authedPost("/user", {
    action: "get",
  });

  return { user };
};

export const useSelect = <T>(
  select: (data: RefreshReturn) => T,
  options?: { staleTime?: number }
) => {
  const isMutating: boolean = useIsMutating(["user"]) > 0;

  return useQuery(["user"], getUser, {
    select,
    initialData: { user: defaultUser },
    enabled: !isMutating,
    refetchOnMount: true,
    ...options,
  });
};

export const useUser = () => useSelect((data) => data.user).data;

export const useDefaultClinicId = () =>
  useSelect((data) => data.user.defaultClinicId || "ALL").data;
