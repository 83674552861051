import "./style/dark.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import AuthenticatedRoutes from "./features/authentication/AuthenticatedRoutes";
import ConfirmSignUp from "./pages/confirmSignUp/ConfirmSignUp";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import Landing from "./pages/public/landing/Landing";
import React from "react";
import SignIn from "./pages/signIn/SignIn";
import SignUp from "./pages/signUp/SignUp";
import { useDarkMode } from "./context/darkModeContext";

const App = () => {
  const { darkMode } = useDarkMode();

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          {/* public pages */}
          <Route path="/" element={<Landing />} />
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/confirmSignUp" element={<ConfirmSignUp />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />

          {/* authenticated pages */}
          <Route path="/*" element={<AuthenticatedRoutes />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
