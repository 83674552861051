import { Accordion, AccordionDetails, AccordionSummary } from "./Accordion";
import { Box, Button, TextField, Typography } from "@mui/material";
import {
  Clinic,
  ClinicStatus,
  PhoneNumberConnectionStatus,
  Provider,
} from "src/libs/models";
import React, { useState } from "react";

import ClinicInfo from "./ClinicInfo";
import ClinicPreferences from "./ClinicPreferences";
import PMSIntegration from "./PMSIntegration";
import PMSTesting from "./PMSTesting";
import PhoneNumberIntegration from "./PhoneNumberIntegration";
import PhoneNumberTesting from "./PhoneNumberTesting";
import ProviderConfiguration from "./ProviderConfiguration";
import VerifyClinicActivationDialog from "./VerifyClinicActivationDialog";
import VerifyClinicDeactivationDialog from "./VerifyClinicStatusDialog";
import { useClinic } from "./queries/useClinic";
import { useIsAdmin } from "src/libs/hooks";
import { useParams } from "react-router-dom";
import { useProviderList } from "./queries/useProviderList";
import { useUpdateStatusMutation } from "./mutations/useUpdateStatus";

const ClinicsPage = () => {
  const { clinicId } = useParams<any>();
  const clinic: Clinic = useClinic(clinicId);
  const providers: Provider[] = useProviderList(clinicId);
  const updateStatusMutation = useUpdateStatusMutation();
  const isAdmin = useIsAdmin();

  const [expanded, setExpanded] = useState<number | false>(0);
  const [
    openVerifyClinicDeactivationDialog,
    setOpenVerifyClinicDeactivationDialog,
  ] = useState(false);
  const [
    openVerifyClinicActivationDialog,
    setOpenVerifyClinicActivationDialog,
  ] = useState(false);

  const updateStatus = async (): Promise<void> => {
    await updateStatusMutation.mutateAsync({
      clinicId,
      status:
        clinic.status === ClinicStatus.ACTIVE
          ? ClinicStatus.INACTIVE
          : ClinicStatus.ACTIVE,
      numberSid: clinic.phoneNumberIntegrationDetails.forwardingClinicNumberSid,
    });
  };

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const canActivateClinic = () => {
    const validAICallManagementPreferences =
      Object.values(clinic.preferences.policyMap).filter((policy) => policy)
        .length > 0;

    const validProviders = providers.some((provider: Provider) => {
      const hasAvailability = provider.availabilities?.length > 0;
      const hasAnAppointmentTypeEnabled = Object.values(
        provider.serviceConfigurations
      ).some((item) => item.enabled);
      return hasAvailability && hasAnAppointmentTypeEnabled;
    });

    const validAcceptingNewPatients = providers.some(
      (provider: Provider) => provider.acceptingNewPatients
    );
    return (
      (clinic.phoneNumberIntegrationDetails.phoneNumberStatus ===
        PhoneNumberConnectionStatus.CONNECTED &&
        clinic.pmsIntegrationDetails.pmsConnected &&
        validAICallManagementPreferences &&
        validProviders &&
        validAcceptingNewPatients) ||
      (clinic.phoneNumberIntegrationDetails.phoneNumberStatus ===
        PhoneNumberConnectionStatus.CONNECTED &&
        !clinic.pmsIntegrationDetails.pmsConnected &&
        validAICallManagementPreferences)
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          margin: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          disabled={
            !canActivateClinic() && clinic.status === ClinicStatus.INACTIVE
          }
          onClick={() =>
            clinic.status === ClinicStatus.ACTIVE
              ? setOpenVerifyClinicDeactivationDialog(true)
              : setOpenVerifyClinicActivationDialog(true)
          }
          sx={{
            backgroundColor:
              clinic.status === ClinicStatus.ACTIVE ? "red" : "green",
            "&:hover": {
              backgroundColor:
                clinic.status === ClinicStatus.ACTIVE ? "#d32f2f" : "#388e3c", // Change these colors as per your requirement
            },
          }}
        >
          {clinic.status === ClinicStatus.ACTIVE ? "Deactivate" : "Activate"}
        </Button>
      </Box>
      <Box
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          width: "80%",
        }}
      >
        <Accordion expanded={expanded === 0} onChange={handleChange(0)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>Clinic Info</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ClinicInfo
                clinicInfo={clinic.info}
                clinicId={clinic.id}
                setExpanded={setExpanded}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>

        {isAdmin && (
          <Accordion expanded={expanded === 1} onChange={handleChange(1)}>
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>PMS Integration</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <PMSIntegration
                  pmsIntegrationDetails={clinic.pmsIntegrationDetails}
                  clinicId={clinic.id}
                  setExpanded={setExpanded}
                  status={clinic.status}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}

        {isAdmin && (
          <Accordion expanded={expanded === 2} onChange={handleChange(2)}>
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>PMS Testing</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <PMSTesting
                  clinicId={clinic.id}
                  clinicStatus={clinic.status}
                  pmsIntegrationDetails={clinic.pmsIntegrationDetails}
                  hasPMSIntegrationFields={
                    clinic.pmsIntegrationDetails.subdomain !== "" &&
                    clinic.pmsIntegrationDetails.locationId !== ""
                  }
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion
          expanded={isAdmin ? expanded === 3 : expanded === 1}
          onChange={handleChange(isAdmin ? 3 : 1)}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Provider Configuration</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ProviderConfiguration
                clinicId={clinic.id}
                clinicStatus={clinic.status}
                pmsIntegrationDetails={clinic.pmsIntegrationDetails}
                setExpanded={setExpanded}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
        {isAdmin && (
          <Accordion expanded={expanded === 4} onChange={handleChange(4)}>
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>Phone Number Integration</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <PhoneNumberIntegration
                  phoneNumberIntegrationDetails={
                    clinic.phoneNumberIntegrationDetails
                  }
                  forwardingClinicNumber={clinic.forwardingClinicNumber}
                  setExpanded={setExpanded}
                  clinicId={clinic.id}
                  clinicStatus={clinic.status}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}
        {isAdmin && (
          <Accordion expanded={expanded === 5} onChange={handleChange(5)}>
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>Phone Number Testing</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <PhoneNumberTesting
                  clinicId={clinic.id}
                  clinicStatus={clinic.status}
                  phoneNumberIntegrationDetails={
                    clinic.phoneNumberIntegrationDetails
                  }
                  hasBaseClinicNumber={
                    clinic.phoneNumberIntegrationDetails.baseClinicNumber !== ""
                  }
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion
          expanded={isAdmin ? expanded === 6 : expanded === 2}
          onChange={handleChange(isAdmin ? 6 : 2)}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>AI Call Management Preferences</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ClinicPreferences
                clinicPreferences={clinic.preferences}
                clinicId={clinic.id}
                pmsConnected={clinic.pmsIntegrationDetails.pmsConnected}
                setExpanded={setExpanded}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <VerifyClinicActivationDialog
        open={openVerifyClinicActivationDialog}
        setOpen={setOpenVerifyClinicActivationDialog}
        updateStatus={updateStatus}
      />
      <VerifyClinicDeactivationDialog
        open={openVerifyClinicDeactivationDialog}
        setOpen={setOpenVerifyClinicDeactivationDialog}
        updateStatus={updateStatus}
      />
    </Box>
  );
};

export default ClinicsPage;
