import { RefreshReturn } from "../queries/useUser";
import { authedPost } from "src/libs/aws";
import { produce } from "immer";
import { useOptimisticUpdate } from "src/libs/query";

interface UpdateUserProps {
  clinicId: string;
}

const updateUserAction =
  () =>
  async ({ clinicId }: UpdateUserProps): Promise<any> => {
    return authedPost("/user", {
      action: "update",
      clinicId,
    });
  };

export const useUpdateUserMutation = () => {
  return useOptimisticUpdate(
    ["user"],
    updateUserAction(),
    ({ clinicId }) =>
      produce((draftUser: RefreshReturn) => {
        draftUser.user.defaultClinicId = clinicId;
      }),
    {
      retry: 3,
      mutationKey: ["user"],
    }
  );
};
