import { QueryFunction, useIsMutating, useQuery } from "react-query";

import { Callback } from "src/libs/models";
import { authedPost } from "src/libs/aws";
import { useTenantId } from "../../tenant/queries";

type QueryKey = ["callbacks", string];

export type RefreshReturn = {
  callbacks: Callback[];
};

export const getCallbacks: QueryFunction<RefreshReturn, QueryKey> = async ({
  queryKey,
}) => {
  const [, tenantId] = queryKey;
  if (!tenantId) {
    throw new Error("Missing tenantId");
  }
  const { callbacks } = await authedPost("/callbacks", {
    action: "get",
    tenantId,
  });

  return { callbacks };
};

export const useSelect = <T>(
  select: (data: RefreshReturn) => T,
  options?: { staleTime?: number }
) => {
  const tenantId: string = useTenantId();
  const isMutating: boolean = useIsMutating(["callbacks", tenantId]) > 0;

  return useQuery(["callbacks", tenantId], getCallbacks, {
    select,
    initialData: { callbacks: [] },
    enabled: !!tenantId && !isMutating,
    refetchOnMount: true,
    ...options,
  });
};

export const useCallbacks = () => useSelect((data) => data.callbacks).data;
