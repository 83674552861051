import { Box } from "@mui/material";
import Datatable from "../../components/datatable/Datatable";
import React from "react";

const Integrations = () => {
  return (
    <Box>
      integrations
      <Datatable />
    </Box>
  );
};

export default Integrations;
