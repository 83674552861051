import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

import { Role } from "../../libs/models";
import { authedPost } from "src/libs/aws";
import { useInviteMemberMutation } from "./mutations/useInviteMemberMutation";
import { useUser } from "src/context/userContext";

interface Props {
  open: boolean;
  setOpen(open: boolean): any;
}

const InviteMemberDialog: React.FC<Props> = ({
  open,
  setOpen,
}): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<Role>(null);
  const inviteMemberMutation = useInviteMemberMutation();

  const inviteMember = async (): Promise<void> => {
    await inviteMemberMutation.mutateAsync({
      email,
      role,
    });
  };

  const handleInviteUser = async (): Promise<void> => {
    inviteMember();
    setOpen(false);
    setEmail("");
    setRole(null);
  };

  const handleCloseDialog = (): void => {
    setOpen(false);
    setEmail("");
    setRole(null);
  };

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={handleCloseDialog}
      title="Invite new member"
    >
      <DialogTitle id="form-dialog-title">Invite New Member</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email Address"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormControl fullWidth>
          <InputLabel id="role-label">Role</InputLabel>
          <Select
            labelId="role-label"
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value as Role)}
            label="Role"
          >
            <MenuItem value={Role.Admin}>{Role.Admin}</MenuItem>
            <MenuItem value={Role.Collaborator}>{Role.Collaborator}</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleCloseDialog} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleInviteUser}
          color="primary"
          disabled={!role || !email}
        >
          Send Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteMemberDialog;
