import { Box, CircularProgress, Typography } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { PhoneNumberConnectionStatus } from "src/libs/models";
import React from "react";

export const StatusIndicator = ({ status, polling, type }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {polling ? (
        <CircularProgress size={24} />
      ) : status === PhoneNumberConnectionStatus.CONNECTED ? (
        <CheckCircleIcon color="success" />
      ) : (
        <ErrorIcon color="error" />
      )}
      <Typography
        variant="subtitle1"
        color={
          status === PhoneNumberConnectionStatus.CONNECTED
            ? "success.main"
            : "error.main"
        }
      >
        {status === PhoneNumberConnectionStatus.CONNECTED
          ? `${type} Connected`
          : `${type} Not Connected`}
      </Typography>
    </Box>
  );
};
