import { Role, unknownMember } from "./models";

import { useMember } from "src/pages/members/queries/useMember";
import { useUser } from "src/context/userContext";

export const useRole = () => {
  const { user } = useUser();
  const { role } = useMember(user?.id) || unknownMember;
  return role || Role.Collaborator;
};

export const useIsAdmin = () => {
  const role = useRole();
  return role === Role.Admin;
};
