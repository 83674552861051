import { QueryFunction, useIsMutating, useQuery } from "react-query";

import { Voicemail } from "src/libs/models";
import { authedPost } from "src/libs/aws";
import { useTenantId } from "../../tenant/queries";

type QueryKey = ["voicemails", string];

export type RefreshReturn = {
  voicemails: Voicemail[];
};

export const getVoicemails: QueryFunction<RefreshReturn, QueryKey> = async ({
  queryKey,
}) => {
  const [, tenantId] = queryKey;
  if (!tenantId) {
    throw new Error("Missing tenantId");
  }
  const { voicemails } = await authedPost("/voicemails", {
    action: "get",
    tenantId,
  });

  return { voicemails };
};

export const useSelect = <T>(
  select: (data: RefreshReturn) => T,
  options?: { staleTime?: number }
) => {
  const tenantId: string = useTenantId();
  const isMutating: boolean = useIsMutating(["voicemails", tenantId]) > 0;

  return useQuery(["voicemails", tenantId], getVoicemails, {
    select,
    initialData: { voicemails: [] },
    enabled: !!tenantId && !isMutating,
    refetchOnMount: true,
    ...options,
  });
};

export const useVoicemails = () => useSelect((data) => data.voicemails).data;
