import { Box } from "@mui/material";
import Datatable from "../../components/datatable/Datatable";
import React from "react";

const Settings = () => {
  return (
    <Box>
      settings
      <Datatable />
    </Box>
  );
};

export default Settings;
