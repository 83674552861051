import { Box } from "@mui/material";
import Navbar from "src/components/navbar/Navbar";
import React from "react";
import Sidebar from "src/components/sidebar/Sidebar";

interface Props {
  children: React.ReactNode;
}

export const Navigation: React.FC<Props> = ({ children }) => {
  return (
    <Box style={{ display: "flex" }}>
      <Box sx={{ flex: "0 0 200px" }}>
        <Sidebar />
      </Box>
      <Box sx={{ flex: "1", display: "flex", flexDirection: "column" }}>
        <Navbar />
        {children}
      </Box>
    </Box>
  );
};

export default Navigation;
