import {
  Availability,
  ClinicStatus,
  PmsIntegrationDetails,
  Provider,
} from "src/libs/models";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AvailabilityBox from "./AvailabilityBox";
import CachedIcon from "@mui/icons-material/Cached";
import CreateProviderAvailabilityDialog from "./CreateProviderAvailabilityDiolog";
import EmptyState from "src/components/EmptyState";
import ProviderList from "../provider/ProviderList";
import ScrollableTableCell from "src/components/ScrollableTableCell";
import { calculateTimeAgo } from "src/libs/misc";
import { durationOptions } from "src/libs/nexhealthModels";
import { useProviderList } from "./queries/useProviderList";
import { useSyncNexhealthMutation } from "./mutations/useSyncNexhealthMutation";
import { useUpdateProvidersMutation } from "./mutations/useUpdateProvidersMutation";

interface Props {
  clinicId: string;
  clinicStatus: ClinicStatus;
  pmsIntegrationDetails: PmsIntegrationDetails;
  setExpanded: React.Dispatch<React.SetStateAction<number>>;
}

const ProviderConfiguration = ({
  clinicId,
  clinicStatus,
  pmsIntegrationDetails,
  setExpanded,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const initialProviderData = useProviderList(clinicId);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [updatedProviderIds, setUpdatedProviderIds] = useState<string[]>([]);
  const [createProviderAvailability, setCreateProviderAvailability] =
    useState(false);
  const [selectedProvider, setSelectedProvider] = useState<string | null>(null);

  useEffect(() => {
    if (initialProviderData) {
      setProviders(initialProviderData);
    }
  }, [initialProviderData]);

  const syncNexhealthMutation = useSyncNexhealthMutation(
    clinicId,
    pmsIntegrationDetails.mapByOperatory
  );
  const updateProviderMutation = useUpdateProvidersMutation(clinicId);

  const handleUpdateProviders = async () => {
    const updatedProviders = providers.filter(
      (provider) => updatedProviderIds.indexOf(provider.providerId) !== -1
    );
    setUpdatedProviderIds([]);

    if (updatedProviders.length > 0) {
      await updateProviderMutation.mutateAsync({
        providers: updatedProviders,
        bearerToken: pmsIntegrationDetails.bearerToken,
        bearerTokenCreateTimestamp:
          pmsIntegrationDetails.bearerTokenCreateTimestamp,
        subdomain: pmsIntegrationDetails.subdomain,
        locationId: pmsIntegrationDetails.locationId,
      });
    }
    setExpanded((prev: number) => prev + 1);
  };

  const syncNexhealthProviders = async () => {
    setLoading(true);
    await syncNexhealthMutation.mutateAsync({
      bearerToken: pmsIntegrationDetails.bearerToken,
      bearerTokenCreateTimestamp:
        pmsIntegrationDetails.bearerTokenCreateTimestamp,
      subdomain: pmsIntegrationDetails.subdomain,
      locationId: pmsIntegrationDetails.locationId,
    });
    setLoading(false);
  };

  // const handleServiceChange = (providerId: string, serviceKey: string) => {
  //   setUpdatedProviderIds((ids) => [...ids, providerId]);
  //   setProviders((currentProviders) =>
  //     currentProviders.map((provider) =>
  //       provider.providerId === providerId
  //         ? {
  //             ...provider,
  //             services: {
  //               ...provider.services,
  //               [serviceKey]: !provider.services[serviceKey],
  //             },
  //           }
  //         : provider
  //     )
  //   );
  // };

  const handleDurationChange = (providerId, serviceKey, newDuration) => {
    // Add providerId to updatedProviderIds if it's not already there
    setUpdatedProviderIds((prevIds) => {
      return [...prevIds, providerId];
    });

    // Update the providers state with the new duration for the specific service
    setProviders((currentProviders) =>
      currentProviders.map((provider) => {
        if (provider.providerId === providerId) {
          // Clone the provider to avoid direct state mutation
          const updatedProvider = { ...provider };
          // Update the specific service configuration
          updatedProvider.serviceConfigurations = {
            ...provider.serviceConfigurations,
            [serviceKey]: {
              ...provider.serviceConfigurations[serviceKey],
              duration: newDuration,
            },
          };
          return updatedProvider;
        }
        return provider;
      })
    );
  };

  const handleAcceptingNewPatientsToggle = (providerId: string) => {
    setUpdatedProviderIds((ids) => [...ids, providerId]);
    setProviders((currentProviders) =>
      currentProviders.map((provider) =>
        provider.providerId === providerId
          ? {
              ...provider,
              acceptingNewPatients: !provider.acceptingNewPatients,
            }
          : provider
      )
    );
  };

  const handleServiceChange = (providerId, serviceKey, isEnabled) => {
    // Add providerId to updatedProviderIds if it's not already there
    setUpdatedProviderIds((prevIds) => {
      return [...prevIds, providerId];
    });

    // Update the providers state with the new enabled status for the specific service
    setProviders((currentProviders) =>
      currentProviders.map((provider) => {
        if (provider.providerId === providerId) {
          // Clone the provider to avoid direct state mutation
          const updatedProvider = { ...provider };
          // Update the specific service configuration
          updatedProvider.serviceConfigurations = {
            ...provider.serviceConfigurations,
            [serviceKey]: {
              ...provider.serviceConfigurations[serviceKey],
              enabled: isEnabled,
            },
          };
          return updatedProvider;
        }
        return provider;
      })
    );
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          onClick={handleUpdateProviders}
          disabled={
            !pmsIntegrationDetails.pmsConnected || providers.length === 0
          }
        >
          Save Provider Details
        </Button>
        <Button
          variant="outlined"
          onClick={syncNexhealthProviders}
          disabled={!pmsIntegrationDetails.pmsConnected}
          sx={{ display: "flex", alignItems: "center", marginLeft: "10px" }}
        >
          <Icon component={CachedIcon} sx={{ marginRight: "8px" }} />
          Sync PMS
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "20%" }}>Provider</TableCell>
              <TableCell
                sx={{
                  maxWidth: "70%",
                  overflowX: "auto",
                }}
              >
                Appointment Types
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: "center" }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <ProviderList
                providers={providers}
                handleAcceptingNewPatientsToggle={
                  handleAcceptingNewPatientsToggle
                }
                handleServiceChange={handleServiceChange}
                handleDurationChange={handleDurationChange}
                setSelectedProvider={setSelectedProvider}
                setCreateProviderAvailability={setCreateProviderAvailability}
                setProviders={setProviders}
                setUpdatedProviderIds={setUpdatedProviderIds}
                pmsIntegrationDetails={pmsIntegrationDetails}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateProviderAvailabilityDialog
        open={createProviderAvailability}
        setOpen={setCreateProviderAvailability}
        setProviders={setProviders}
        setUpdatedProviderIds={setUpdatedProviderIds}
        providerId={selectedProvider}
        operatories={pmsIntegrationDetails.operatories}
        mapByOperatory={pmsIntegrationDetails.mapByOperatory}
      />
    </Box>
  );
};

export default ProviderConfiguration;
