import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { PolicyMap, ServiceType } from "src/libs/nexhealthModels";
import React, { useState } from "react";

import { durationOptions } from "src/libs/nexhealthModels";
import { useUpdateClinicMutation } from "./mutations/useUpdateClinicMutation";

interface Props {
  clinicPreferences: {
    policyMap: PolicyMap;
    callGreeting: {
      clinicName: string;
      receptionistName: string;
    };
  };
  pmsConnected: boolean;
  clinicId: string;
  setExpanded: React.Dispatch<React.SetStateAction<number>>;
}
const ClinicPreferences: React.FunctionComponent<Props> = ({
  clinicPreferences,
  clinicId,
  pmsConnected,
  setExpanded,
}) => {
  const [policyMap, setPolicyMap] = useState<PolicyMap>(
    clinicPreferences.policyMap
  );

  const [receptionistName, setReceptionistName] = useState(
    clinicPreferences.callGreeting.receptionistName || ""
  );

  const [clinicNameCallGreeting, setClinicNameCallGreeting] = useState(
    clinicPreferences.callGreeting.clinicName || ""
  );

  const UpdateClinicMutation = useUpdateClinicMutation();

  const updateClinic = async (): Promise<void> => {
    await UpdateClinicMutation.mutateAsync({
      clinicId,
      policyMap,
      receptionistName,
      clinicNameCallGreeting,
    });
  };

  const handleToggle = (policy: string) => {
    setPolicyMap({
      ...policyMap,
      [policy]: !policyMap[policy],
    });
  };

  const handleUpdateClinic = () => {
    updateClinic();
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              marginLeft: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              <Box>Services</Box>
              {Object.keys(policyMap).map((policy) => (
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={policyMap[policy]}
                      onChange={() => handleToggle(policy)}
                      name={policy}
                      disabled={
                        policy !== ServiceType.SCHEDULE_CALLBACK &&
                        !pmsConnected
                      }
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "green", // This is the color when the switch is on
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor: "green", // This is the track color when the switch is on
                          },
                      }}
                    />
                  }
                  label={policy}
                  key={policy}
                />
              ))}
            </Box>
            <Box sx={{ marginTop: "15px" }}>
              <Box>Call Greeting</Box>
              <TextField
                margin="normal"
                required
                label="Clinic Name"
                sx={{ width: "80%" }}
                id="clinicName"
                name="clinicName"
                autoComplete="clinicName"
                value={clinicNameCallGreeting}
                onChange={(e) => setClinicNameCallGreeting(e.target.value)}
              />
            </Box>
            <Box>
              <TextField
                margin="normal"
                required
                label="Receptionist Name"
                sx={{ width: "80%" }}
                id="receptionistName"
                name="receptionistName"
                autoComplete="receptionistName"
                value={receptionistName}
                onChange={(e) => setReceptionistName(e.target.value)}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
        </Box>
        <Box
          sx={{
            marginLeft: "20px",
            display: "flex",
            justifyContent: "flex-start",
          }}
        ></Box>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "flex-end", marginTop: "5px" }}
      >
        <Button onClick={handleUpdateClinic} variant="contained">
          Save Clinic Preferences
        </Button>
      </Box>
    </Box>
  );
};

export default ClinicPreferences;
