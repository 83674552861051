import { useMutation, useQueryClient } from "react-query";

import { authedPost } from "src/libs/aws";
import { useTenantId } from "src/pages/tenant/queries";

interface SyncNexhealthProps {
  subdomain: string;
  locationId: string;
  bearerToken: string;
  bearerTokenCreateTimestamp: string;
}

const syncNexhealthAction =
  (tenantId: string, clinicId: string, mapByOperatory: boolean) =>
  async ({
    subdomain,
    locationId,
    bearerToken,
    bearerTokenCreateTimestamp,
  }: SyncNexhealthProps): Promise<any> => {
    return authedPost("/clinics", {
      action: "syncNexhealth",
      nexhealthParameters: {
        tenantId,
        clinicId,
        subdomain,
        locationId,
        bearerToken,
        bearerTokenCreateTimestamp,
      },
      mapByOperatory,
    });
  };

// TODO: possibly need to invalidate cache after call succeeds
export const useSyncNexhealthMutation = (
  clinicId: string,
  mapByOperatory: boolean
) => {
  const queryClient = useQueryClient();
  const tenantId = useTenantId();

  return useMutation(syncNexhealthAction(tenantId, clinicId, mapByOperatory), {
    onSettled: () => {
      queryClient.invalidateQueries(["providers", clinicId]);
      if (mapByOperatory) {
        queryClient.invalidateQueries(["clinics", tenantId]);
      }
    },
    retry: 3,
    mutationKey: ["clinic", "syncNexhealthMutation", clinicId],
  });
};
