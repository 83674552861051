import { Invite, Member } from "src/libs/models";
import { QueryFunction, useIsMutating, useQuery } from "react-query";

import { authedPost } from "src/libs/aws";
import { useTenantId } from "../tenant/queries";

type QueryKey = ["membersAndInvites", string];

export type RefreshReturn = {
  members: Member[];
  invites: Invite[];
};

export const getMembersAndInvites: QueryFunction<
  RefreshReturn,
  QueryKey
> = async ({ queryKey }) => {
  const [, tenantId] = queryKey;
  if (!tenantId) {
    throw new Error("Missing tenantId");
  }
  const { members, invites } = await authedPost("/tenant", {
    action: "getMembersAndInvites",
    tenantId,
  });

  return { members, invites };
};

export const useSelect = <T>(
  select: (data: RefreshReturn) => T,
  options?: { staleTime?: number }
) => {
  const tenantId: string = useTenantId();
  const isMutating: boolean =
    useIsMutating(["membersAndInvites", "InviteMember", tenantId]) > 0;

  return useQuery(["membersAndInvites", tenantId], getMembersAndInvites, {
    select,
    initialData: { members: [], invites: [] },
    enabled: !!tenantId && !isMutating,
    refetchOnMount: true,
    ...options,
  });
};

export const useMembers = () => useSelect((data) => data.members).data;

export const useInvites = () => useSelect((data) => data.invites).data;
