import { ClinicStatus } from "src/libs/models";
import { RefreshReturn } from "../queries/useClinicsList";
import { authedPost } from "src/libs/aws";
import { produce } from "immer";
import { useOptimisticUpdate } from "src/libs/query";
import { useTenantId } from "src/pages/tenant/queries";

interface UpdateStatusProps {
  clinicId: string;
  status: ClinicStatus;
  numberSid: string;
}

const updateStatusAction =
  (tenantId: string) =>
  async ({ status, clinicId, numberSid }: UpdateStatusProps): Promise<any> => {
    return authedPost("/clinics", {
      action: "updateStatus",
      tenantId,
      clinicId,
      status,
      numberSid,
    });
  };

export const useUpdateStatusMutation = () => {
  const tenantId: string = useTenantId();

  return useOptimisticUpdate(
    ["clinics", tenantId],
    updateStatusAction(tenantId),
    ({ status, clinicId }) =>
      produce((draftClinics: RefreshReturn) => {
        if (draftClinics.clinics[clinicId] === undefined)
          throw new Error("Invalid clientId");

        draftClinics.clinics[clinicId] = {
          ...draftClinics.clinics[clinicId],
          status,
        };
      }),
    {
      retry: 3,
      mutationKey: ["clinics", tenantId],
    }
  );
};
