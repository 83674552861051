import { authedPost, unauthedPost } from "src/libs/aws";

import { Box } from "@mui/material";
import Chart from "../../components/chart/Chart";
import Featured from "../../components/featured/Featured";
import React from "react";
import Table from "../../components/table/Table";
import Widget from "../../components/widget/Widget";

const Dashboard = () => {
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          flex: 6,
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "20px",
            gap: "20px",
          }}
        >
          <Widget type="user" />
          <Widget type="order" />
          <Widget type="earning" />
          <Widget type="balance" />
        </Box>
        <Box
          sx={{
            display: "flex",
            padding: "5px 20px",
            gap: "20px",
          }}
        >
          <Featured />
          <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
        </Box>
        <Box
          sx={{
            boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)",
            padding: "20px",
            margin: "20px",
          }}
        >
          <Box
            sx={{
              fontWeight: 500,
              color: "gray",
              marginBottom: "15px",
            }}
          >
            Latest Transactions
          </Box>
          <Table />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
