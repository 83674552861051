import { Provider } from "src/libs/models";
import { RefreshReturn } from "../queries/useProviderList";
import { authedPost } from "src/libs/aws";
import { produce } from "immer";
import { useOptimisticUpdate } from "src/libs/query";
import { useTenantId } from "src/pages/tenant/queries";

interface UpdateProvidersProps {
  providers: Provider[];
  subdomain: string;
  locationId: string;
  bearerToken: string;
  bearerTokenCreateTimestamp: string;
}

const updateProvidersAction =
  (clinicId: string, tenantId: string) =>
  async ({
    subdomain,
    locationId,
    bearerToken,
    bearerTokenCreateTimestamp,
    providers,
  }: UpdateProvidersProps): Promise<any> => {
    return authedPost("/providers", {
      action: "update",
      providers,
      nexhealthParameters: {
        subdomain,
        locationId,
        bearerToken,
        bearerTokenCreateTimestamp,
        clinicId,
        tenantId,
      },
    });
  };

export const useUpdateProvidersMutation = (clinicId: string) => {
  const tenantId = useTenantId();

  return useOptimisticUpdate(
    ["providers", clinicId],
    updateProvidersAction(clinicId, tenantId),
    ({ providers }) =>
      produce((draftProviders: RefreshReturn) => {
        draftProviders.providers = draftProviders.providers.map((provider) => {
          const updatedProvider = providers.find(
            (p) => p.providerId === provider.providerId
          );
          if (updatedProvider) {
            return updatedProvider;
          }
          return provider;
        });
      }),
    {
      retry: 3,
      mutationKey: ["provider", clinicId],
    }
  );
};
