import Box from "@mui/material/Box";
import React from "react";
import { TextField } from "@mui/material";

const Setup = ({ name, handleName }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/* <Typography sx={{}}>Organization Name</Typography> */}
      <TextField
        sx={{ width: "400px" }}
        margin="normal"
        fullWidth
        id="name"
        label="Organization Name"
        name="name"
        autoComplete="name"
        autoFocus
        value={name}
        onChange={(e) => handleName(e.target.value)}
      />
    </Box>
  );
};

export default Setup;
