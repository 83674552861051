import {
  AppointmentType,
  defaultReceptionistName,
} from "src/libs/nexhealthModels";
import { ClinicStatus, PhoneNumberConnectionStatus } from "src/libs/models";
import { createId, enumToObjectWithFixedValue } from "src/libs/misc";

import { RefreshReturn } from "../queries/useClinicsList";
import { authedPost } from "src/libs/aws";
import { defaultPolicyMap } from "src/libs/nexhealthModels";
import { produce } from "immer";
import { useOptimisticUpdate } from "src/libs/query";
import { useTenantId } from "src/pages/tenant/queries";

interface CreateClinicProps {
  clinicName: string;
  clinicAddress: string;
  clinicManager: string;
}

const createClinicAction =
  (tenantId: string, clinicId: string) =>
  async ({
    clinicName,
    clinicAddress,
    clinicManager,
  }: CreateClinicProps): Promise<any> => {
    return authedPost("/clinics", {
      action: "create",
      tenantId,
      clinicId,
      clinicName,
      clinicAddress,
      clinicManager,
    });
  };

export const useCreateClinicMutation = () => {
  const tenantId: string = useTenantId();
  const clinicId: string = createId();

  return useOptimisticUpdate(
    ["clinics", tenantId],
    createClinicAction(tenantId, clinicId),
    ({ clinicName, clinicAddress, clinicManager }) =>
      produce((draftClinics: RefreshReturn) => {
        draftClinics.clinics[clinicId] = {
          id: clinicId,
          info: {
            name: clinicName,
            address: clinicAddress,
            manager: clinicManager,
          },
          pmsIntegrationDetails: {
            bearerToken: "",
            bearerTokenCreateTimestamp: "",
            subdomain: "",
            locationId: "",
            tz: "",
            pmsConnected: false,
            lastTestedTimestamp: "",
          },
          phoneNumberIntegrationDetails: {
            forwardingClinicNumberFriendlyName: "",
            forwardingClinicNumberSid: "",
            baseClinicNumber: "",
            phoneNumberStatus: PhoneNumberConnectionStatus.NOT_CONNECTED,
            lastTestedTimestamp: "",
          },
          forwardingClinicNumber: "",
          preferences: {
            policyMap: defaultPolicyMap,
            callGreeting: {
              clinicName,
              receptionistName: defaultReceptionistName,
              missedCallRecordingURL: "",
              voicemailRecordingURL: "",
            },
          },
          status: ClinicStatus.INACTIVE,
        };
      }),
    {
      retry: 3,
      mutationKey: ["clinics", tenantId],
    }
  );
};
