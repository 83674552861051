import { Theme, createTheme } from "@mui/material/styles";

export const theme: Theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        // Style overrides for the "contained" variant
        contained: {
          // Custom styles here
          backgroundColor: "#1976d2", // Example: Change default color
          "&:hover": {
            backgroundColor: "#115293",
          },
        },
        // Style overrides for the "outlined" variant
      },
      // Setting default props for all buttons
      defaultProps: {
        variant: "contained",
        color: "primary",
      },
    },
  },
});
