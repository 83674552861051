import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

import { useCreateClinicMutation } from "./mutations/useCreateClinicMutation";

interface Props {
  open: boolean;
  setOpen(open: boolean): any;
}

const CreateClinicDialog: React.FC<Props> = ({
  open,
  setOpen,
}): JSX.Element => {
  const [clinicName, setClinicName] = useState<string>("");
  const [clinicAddress, setClinicAddress] = useState<string>("");
  const [clinicManager, setClinicManager] = useState<string>("");

  const useCreateClinic = useCreateClinicMutation();

  const createClinic = async (): Promise<void> => {
    await useCreateClinic.mutateAsync({
      clinicName,
      clinicAddress,
      clinicManager,
    });
  };

  const handleCreateClinic = async (): Promise<void> => {
    createClinic();
    setOpen(false);
    setClinicName("");
    setClinicAddress("");
    setClinicManager("");
  };

  const handleCloseDialog = (): void => {
    setOpen(false);
    setClinicName("");
    setClinicAddress("");
    setClinicManager("");
  };

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={handleCloseDialog}
      title="Invite new member"
    >
      <DialogTitle id="form-dialog-title">Create new clinic</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="clinicName"
          label="Clinic Name"
          type="text"
          fullWidth
          value={clinicName}
          onChange={(e) => setClinicName(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="clinicLocation"
          label="Clinic Location"
          type="text"
          fullWidth
          value={clinicAddress}
          onChange={(e) => setClinicAddress(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="officeManager"
          label="Clinic Office Manager"
          type="text"
          fullWidth
          value={clinicManager}
          onChange={(e) => setClinicManager(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleCreateClinic}
          color="primary"
          disabled={!clinicName || !clinicAddress || !clinicManager}
        >
          Create Clinic
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateClinicDialog;
