import { Clinic } from "src/libs/models";
import { useClinicsList } from "./useClinicsList";

export const useClinicIdMapping = () => {
  const { data } = useClinicsList();
  const clinics: Clinic[] = data;

  return clinics?.reduce((acc, clinic) => {
    acc[clinic.id] = {
      clinicName: clinic.info.name,
      timeZone: clinic.pmsIntegrationDetails.tz,
    };
    return acc;
  }, {} as { [id: string]: { clinicName: string; timeZone: string } });
};
