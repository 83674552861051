import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  Day,
  OccurrenceType,
  Operatory,
  Provider,
  Recurrence,
  SpecificDate,
  Unit,
} from "src/libs/models";
import React, { useState } from "react";
import { defaultBeginTime, defaultEndTime } from "src/libs/constants";

import { createId } from "src/libs/misc";

interface Props {
  open: boolean;
  setOpen(open: boolean): any;
  providerId: string;
  setProviders: React.Dispatch<React.SetStateAction<Provider[]>>;
  setUpdatedProviderIds: React.Dispatch<React.SetStateAction<string[]>>;
  operatories?: Operatory[];
  mapByOperatory?: boolean;
}

const CreateProviderAvailabilityDialog: React.FC<Props> = ({
  open,
  setOpen,
  providerId,
  setProviders,
  setUpdatedProviderIds,
  operatories = [],
  mapByOperatory,
}): JSX.Element => {
  const [beginTime, setBeginTime] = useState<string>(defaultBeginTime);
  const [endTime, setEndTime] = useState<string>(defaultEndTime);
  const [operatoryId, setOperatoryId] = useState<string>("");
  const [occurrenceType, setOccurrenceType] = useState<
    OccurrenceType | undefined
  >(undefined);
  const [specificDate, setSpecificDate] = useState<SpecificDate | undefined>(
    undefined
  );
  const [recurrence, setRecurrence] = useState<Recurrence | undefined>(
    undefined
  );
  const [days, setDays] = useState<Day[] | undefined>(undefined);

  const createProviderAvailability = ({
    beginTime,
    endTime,
    operatoryId,
    occurrenceType,
    specificDate,
    recurrence,
    days,
  }) => {
    setProviders((prevProviders) => {
      const newProviders = prevProviders.map((provider) => {
        if (provider.providerId === providerId) {
          return {
            ...provider,
            availabilities: [
              ...(provider.availabilities || []),
              {
                availabilityId: createId(),
                beginTime,
                endTime: endTime,
                operatoryId: operatoryId,
                operatoryName: operatories.find(
                  (operatory) => operatory.operatoryId === operatoryId
                )?.operatoryName,
                occurrenceType: occurrenceType,
                specificDate: specificDate,
                recurrence: recurrence,
                days: days,
                active: true,
              },
            ],
          };
        }
        return provider;
      });
      return newProviders;
    });
  };

  const handleCreateProviderAvailability = async () => {
    // Capture state and pass to function before resetting
    createProviderAvailability({
      beginTime,
      endTime,
      operatoryId,
      occurrenceType,
      specificDate,
      recurrence,
      days,
    });
    setUpdatedProviderIds((prev) => [...prev, providerId]);
    setOpen(false);
    // Reset state after using the values
    setBeginTime(defaultBeginTime);
    setEndTime(defaultEndTime);
    setOperatoryId("");
    setOccurrenceType(undefined);
    setSpecificDate(undefined);
    setRecurrence(undefined);
    setDays(undefined);
  };

  const handleCloseDialog = (): void => {
    setOpen(false);
    setBeginTime(defaultBeginTime);
    setEndTime(defaultEndTime);
    setOperatoryId("");
    setOccurrenceType(undefined);
    setSpecificDate(undefined);
    setRecurrence(undefined);
    setDays(undefined);
  };

  const renderOccurrenceUI = (): JSX.Element => {
    switch (occurrenceType) {
      case OccurrenceType.SPECIFIC_DATE:
        return (
          <TextField
            margin="dense"
            id="specificDate"
            label="Specific Date"
            type="date"
            value={specificDate}
            onChange={(e) => {
              setSpecificDate(e.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            sx={{ width: "auto", cursor: "pointer" }}
          />
        );
      case OccurrenceType.RECURRENCE:
        return (
          <>
            <Box sx={{ display: "flex" }}>
              <TextField
                margin="dense"
                id="ref"
                label="Reference Date"
                type="date"
                value={recurrence?.ref || ""}
                onChange={(e) => {
                  setRecurrence({
                    ...recurrence,
                    ref: e.target.value,
                  });
                }}
                InputLabelProps={{ shrink: true }}
                sx={{ width: "auto", cursor: "pointer" }}
              />

              <TextField
                margin="dense"
                id="num"
                label="Number"
                type="number"
                value={recurrence?.num || ""}
                onChange={(e) => {
                  const newValue = parseInt(e.target.value, 10);
                  if (!isNaN(newValue)) {
                    setRecurrence({
                      ...recurrence,
                      num: Math.max(1, newValue), // Ensures the number is at least 1
                    });
                  }
                }}
                sx={{ width: "auto" }}
              />
            </Box>
            <Box>
              <FormControl fullWidth margin="normal">
                <InputLabel id="unit-select-label">Unit</InputLabel>
                <Select
                  labelId="unit-select-label"
                  id="unit-select"
                  value={recurrence?.unit || ""}
                  label="Unit"
                  onChange={(e) =>
                    setRecurrence({
                      ...recurrence,
                      unit: e.target.value as Unit,
                    })
                  }
                >
                  <MenuItem value={Unit.DAY}>Days</MenuItem>
                  <MenuItem value={Unit.WEEK}>Weeks</MenuItem>
                  <MenuItem value={Unit.MONTH}>Months</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </>
        );
      case OccurrenceType.DAYS:
        const handleDayChange = (day: Day) => {
          const updatedDays = days?.includes(day)
            ? days.filter((d) => d !== day)
            : [...(days || []), day];
          setDays(updatedDays);
        };

        return (
          <FormControl component="fieldset" margin="normal">
            <FormGroup>
              {Object.values(Day).map((day: Day) => (
                <FormControlLabel
                  key={day}
                  control={
                    <Checkbox
                      checked={(days || [])?.includes(day)}
                      onChange={() => handleDayChange(day)}
                    />
                  }
                  label={day}
                />
              ))}
            </FormGroup>
          </FormControl>
        );

      default:
        return <></>;
    }
  };

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Create Provider Availability
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            gap: 2,
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            id="beginTime"
            label="Begin Time"
            type="time"
            value={beginTime}
            onChange={(e) => setBeginTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: 300 }}
            sx={{ minWidth: "40%", cursor: "pointer" }}
          />
          <TextField
            margin="dense"
            id="endTime"
            label="End Time"
            type="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: 300 }}
            sx={{ minWidth: "40%", cursor: "pointer" }}
          />
        </Box>

        <FormControl fullWidth margin="normal">
          <InputLabel id="occurrence-type-select-label">
            Availability Type
          </InputLabel>
          <Select
            labelId="occurrence-type-select-label"
            id="occurrence-type-select"
            value={occurrenceType}
            label="Select Occurrence Type"
            onChange={(event) => {
              setOccurrenceType(event.target.value as OccurrenceType);
            }}
          >
            {Object.values(OccurrenceType).map(
              (occurrenceType: OccurrenceType) => (
                <MenuItem key={occurrenceType} value={occurrenceType}>
                  {occurrenceType}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        {renderOccurrenceUI()}
        {mapByOperatory && (
          <FormControl fullWidth margin="normal">
            <InputLabel id="operatory-select-label">
              Select Operatory
            </InputLabel>
            <Select
              labelId="operatory-select-label"
              id="operatory-select"
              value={operatoryId}
              label="Select Operatory" // This provides the title for the select menu
              onChange={(event) => setOperatoryId(event.target.value as string)}
            >
              {operatories.map((operatory: Operatory) => (
                <MenuItem
                  key={operatory.operatoryId}
                  value={operatory.operatoryId}
                >
                  {operatory.operatoryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleCloseDialog} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleCreateProviderAvailability();
          }}
          color="primary"
          disabled={
            !beginTime ||
            !endTime ||
            (!operatoryId && mapByOperatory) ||
            !occurrenceType ||
            (occurrenceType === OccurrenceType.SPECIFIC_DATE &&
              !specificDate) ||
            (occurrenceType === OccurrenceType.RECURRENCE &&
              (!recurrence?.ref || !recurrence?.num || !recurrence?.unit)) ||
            (occurrenceType === OccurrenceType.DAYS && !days)
          }
        >
          Create Provider Availability
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateProviderAvailabilityDialog;
