import * as React from "react";

import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Callback, CallbackState, Clinic } from "src/libs/models";
import { formatDate, formatPhoneNumber, truncateText } from "src/libs/misc";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import EmptyState from "src/components/EmptyState";
import FilterListIcon from "@mui/icons-material/FilterList";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { debounce } from "lodash";
import { keyframes } from "@emotion/react";
import { useCallbacks } from "./queries/useCallbacks";
import { useClinicsList } from "../clinics/queries/useClinicsList";
import { useDefaultClinicId } from "../profile/queries/useUser";
import { useNavigate } from "react-router-dom";
import { useUpdateCallbackMutation } from "./mutations/useUpdateCallback";

// Define a mapping for state colors based on your CallbackState enum
const stateColors = {
  PENDING: "#FF9800", // Orange for waiting or in-process actions
  COMPLETED: "#8BC34A", // Light green for success and completion
  CANCELLED: "#9E9E9E", // Grey for neutral, canceled actions
  ATTEMPTED: "#03A9F4", // Light blue for attempts that indicate a need for further action
};

const patientStatusColors = {
  New: "#81c784",
  Existing: "#90a4ae",
};

const additionalInfoColors = {
  patientFullName: "#ADD8E6", // Light Blue
  patientDateOfBirth: "#CAB8FF", // Lavender
  patientEmail: "#B39DDB", // Lavender, same as patientDOB for consistency
  entryTimestamp: "#FFD700", // Gold
};

const CallbacksTable = () => {
  // basic state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [stateAnchorEl, setStateAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const defaultClinicId = useDefaultClinicId();
  // filtering Info
  const [stateFilter, setStateFilter] = useState<CallbackState[]>(
    Object.values(CallbackState)
  );
  const initialized = useRef(false);

  const [patientStatusFilter, setPatientStatusFilter] = useState<
    ("New" | "Existing" | "Unknown")[]
  >(["New", "Existing", "Unknown"]);

  const clinicIds = (useClinicsList().data || []).map(
    (clinic: Clinic) => clinic.id
  );

  // const [clinicIdsFilter, setClinicIdsFilter] = useState<string[]>([]);
  const clinicIdsInitialized = useRef(false);

  const [sortDirection, setSortDirection] = useState("desc"); // "asc" | "desc"
  const updateCallbackMutation = useUpdateCallbackMutation();
  // useEffect(() => {
  //   if (clinicIds.length > 0 && !clinicIdsInitialized.current) {
  //     setClinicIdsFilter(clinicIds);
  //     clinicIdsInitialized.current = true;
  //   }
  // }, [clinicIds]);

  const callbacks: Callback[] = useCallbacks() || [];
  const [noteEdits, setNoteEdits] = useState<{ [key: string]: string }>({});
  const filteredCallbacks = callbacks.filter((callback: Callback) => {
    return (
      stateFilter.includes(callback.state) &&
      patientStatusFilter.includes(
        callback.newPatient === undefined
          ? "Unknown"
          : callback.newPatient
          ? "New"
          : "Existing"
      ) &&
      (defaultClinicId === "ALL" || defaultClinicId === callback.clinicId)
    );
  });

  const sortedCallbacks = [...filteredCallbacks].sort((a, b) => {
    const dateA = new Date(a.createTimestamp);
    const dateB = new Date(b.createTimestamp);

    return sortDirection === "asc"
      ? dateA.getTime() - dateB.getTime()
      : dateB.getTime() - dateA.getTime();
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleStatusMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setStateAnchorEl(event.currentTarget);
  };

  // Handle selecting a filter option
  const handleStatusFilterChange = (status: CallbackState) => {
    setStateFilter((currentFilters) => {
      const isStatusFiltered = currentFilters.includes(status);
      if (isStatusFiltered) {
        return currentFilters.filter((s) => s !== status);
      } else {
        return [...currentFilters, status];
      }
    });
  };

  useEffect(() => {
    if (callbacks && !initialized.current) {
      const initialNotes = callbacks.reduce((acc, callback: Callback) => {
        acc[callback.callbackId] = callback.notes;
        return acc;
      }, {});
      setNoteEdits(initialNotes);
      initialized.current = true;
    }
  }, [callbacks]);

  const handleCallbackStateChange = async (
    callbackId: string,
    state: CallbackState
  ): Promise<void> => {
    await updateCallbackMutation.mutateAsync({
      callbackId,
      state,
    });
  };

  // Debounced function to simulate saving notes to a server or database
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveNotes = useCallback(
    debounce(async (callbackId: string, notes: string) => {
      console.log(`Saving note for callbackId ${callbackId}: ${notes}`);

      await updateCallbackMutation.mutateAsync({
        callbackId,
        notes,
      });
    }, 1000),
    []
  ); // 1000 ms debounce time

  const handleNoteInputChange = (callbackId, newNote) => {
    setNoteEdits((prevNotes) => ({
      ...prevNotes,
      [callbackId]: newNote,
    }));
  };

  // debounce save operation
  const handleNoteEdit = (callbackId: string, newNote: string) => {
    handleNoteInputChange(callbackId, newNote);
    saveNotes(callbackId, newNote);
  };

  if (callbacks.length === 0) {
    return <EmptyState text="No Callbacks found" sx={{ marginTop: "30px" }} />;
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                key={"1"}
                align="left"
                style={{ maxWidth: "35px", minWidth: "35px" }}
              >
                Patient Number
              </TableCell>
              <TableCell key={"2"} align="left" style={{ maxWidth: "120px" }}>
                Callback Summary
              </TableCell>
              <TableCell key={"3"} align="left" style={{ maxWidth: "75px" }}>
                Context
              </TableCell>

              <TableCell key={"4"} align="left" style={{ maxWidth: "15px" }}>
                Status
                <IconButton
                  aria-label="filter list"
                  onClick={handleStatusMenuClick}
                  size="small"
                >
                  <FilterListIcon />
                </IconButton>
                <Menu
                  anchorEl={stateAnchorEl}
                  open={Boolean(stateAnchorEl)}
                  onClose={() => setStateAnchorEl(null)}
                >
                  {Object.values(CallbackState).map((status) => (
                    <MenuItem
                      key={status}
                      onClick={() => handleStatusFilterChange(status)}
                    >
                      <Checkbox checked={stateFilter.indexOf(status) > -1} />
                      <ListItemText primary={status} />
                    </MenuItem>
                  ))}
                </Menu>
              </TableCell>
              <TableCell key={"5"} align="right" style={{ maxWidth: "20px" }}>
                Last updated
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedCallbacks
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((callback: Callback) => {
                const {
                  newPatient,
                  patientFirstName,
                  patientLastName,
                  patientEmail,
                  patientDateOfBirth,
                } = callback;

                const patientFullName =
                  [patientFirstName.trim(), patientLastName.trim()]
                    .filter(Boolean)
                    .join(" ") || "";

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={callback.callbackId}
                    sx={{ height: "90px" }}
                  >
                    <TableCell
                      key={"1"}
                      align="left"
                      sx={{ maxWidth: "35px", minWidth: "35px" }}
                    >
                      {formatPhoneNumber(callback.patientNumber)}
                    </TableCell>
                    <TableCell
                      key={"2"}
                      align="left"
                      sx={{
                        maxWidth: "120px",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box>
                          <Box component="span" sx={{ fontWeight: "700" }}>
                            Reason
                          </Box>
                          : {callback.reason}
                          {callback.conversationId && (
                            <IconButton
                              aria-label="show more"
                              size="small"
                              onClick={() => {
                                // Construct the full URL
                                const url = `${window.location.origin}/conversations/${callback.conversationId}`;
                                window.open(url, "_blank");
                              }}
                            >
                              <OpenInNewIcon fontSize="inherit" />
                            </IconButton>
                          )}
                        </Box>
                        <Box>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={noteEdits[callback.callbackId] || ""}
                            onChange={(e) =>
                              handleNoteEdit(
                                callback.callbackId,
                                e.target.value
                              )
                            }
                            placeholder="Edit Notes"
                            InputProps={{
                              style: { fontSize: "0.875rem" }, // Smaller text size for better table fitting
                            }}
                            margin="dense" // Reduces the space used by the TextField
                            multiline // Enables multiline input
                            minRows={2} // Minimum number of rows to display (can be adjusted as needed)
                            maxRows={6} // Maximum number of rows before scrollbar appears (optional)
                          />
                        </Box>
                      </Box>
                      {/* <Box>
                        <Box component="span" sx={{ fontWeight: "700" }}>
                          Preferred Callback Time
                        </Box>
                        : {callback.preferredCallbackTime}
                      </Box> */}
                    </TableCell>
                    <TableCell key={"3"} align="left" sx={{ maxWidth: "75px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                          fontSize: "0.75rem",
                        }}
                      >
                        {patientFullName && (
                          <Chip
                            label={`patient name: ${patientFullName}`}
                            size="small"
                            sx={{
                              backgroundColor:
                                additionalInfoColors.patientFullName,
                            }}
                            key={patientFullName}
                          />
                        )}
                        {patientDateOfBirth && (
                          <Chip
                            label={`patient DOB: ${patientDateOfBirth}`}
                            size="small"
                            sx={{
                              backgroundColor:
                                additionalInfoColors.patientDateOfBirth,
                            }}
                            key={patientDateOfBirth}
                          />
                        )}
                        {patientEmail && (
                          <Chip
                            label={`patient email: ${patientEmail}`}
                            size="small"
                            sx={{
                              backgroundColor:
                                additionalInfoColors.patientEmail,
                            }}
                            key={patientEmail}
                          />
                        )}
                        {newPatient !== undefined && (
                          <Chip
                            label={
                              newPatient ? "new patient" : "existing patient"
                            }
                            sx={{
                              backgroundColor:
                                patientStatusColors[
                                  newPatient ? "New" : "Existing"
                                ],
                            }}
                            size="small"
                            key={"patientStatus"}
                          />
                        )}

                        {callback.createTimestamp && (
                          <Chip
                            label={`entry timestamp: ${formatDate(
                              callback.createTimestamp
                            )}`}
                            size="small"
                            sx={{
                              backgroundColor:
                                additionalInfoColors.entryTimestamp,
                            }}
                            key={"createTimestamp"}
                          />
                        )}
                      </Box>
                    </TableCell>

                    <TableCell
                      key={"4"}
                      align="left"
                      sx={{
                        maxWidth: "15px",
                      }}
                    >
                      <Select
                        labelId="role-select-label"
                        id="role-select"
                        value={callback.state}
                        onChange={(event) =>
                          handleCallbackStateChange(
                            callback.callbackId,
                            event.target.value as CallbackState
                          )
                        }
                        sx={{
                          height: "30px",
                          backgroundColor: stateColors[callback.state],
                          color: "black",
                        }}
                      >
                        <MenuItem value={CallbackState.PENDING}>
                          PENDING
                        </MenuItem>
                        <MenuItem value={CallbackState.COMPLETED}>
                          COMPLETED
                        </MenuItem>
                        <MenuItem value={CallbackState.ATTEMPTED}>
                          ATTEMPTED
                        </MenuItem>
                        <MenuItem value={CallbackState.CANCELLED}>
                          CANCELLED
                        </MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell
                      key={"5"}
                      align="right"
                      sx={{ maxWidth: "20px" }}
                    >
                      {formatDate(callback.editTimestamp)}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={sortedCallbacks.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default CallbacksTable;
