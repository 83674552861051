import { QueryFunction, useIsMutating, useQuery } from "react-query";

import { Clinic } from "src/libs/models";
import { authedPost } from "src/libs/aws";
import { useTenantId } from "../../tenant/queries";

type QueryKey = ["clinics", string];

export type RefreshReturn = {
  clinics: { [id: string]: Clinic };
};

export const getClinics: QueryFunction<RefreshReturn, QueryKey> = async ({
  queryKey,
}) => {
  const [, tenantId] = queryKey;
  if (!tenantId) {
    throw new Error("Missing tenantId");
  }
  const { list } = await authedPost("/clinics", {
    action: "get",
    tenantId,
  });

  // mapping so individual access and optimistic updates are faster (slower fetches)
  const clinics = Object.fromEntries(
    list.map((clinic: Clinic) => {
      return [clinic.id, clinic];
    })
  );

  return { clinics };
};

export const useSelect = <T>(
  select: (data: RefreshReturn) => T,
  options?: { staleTime?: number; enabled?: boolean }
) => {
  const tenantId: string = useTenantId();
  const isMutating: boolean = useIsMutating(["clinics", tenantId]) > 0;

  return useQuery(["clinics", tenantId], getClinics, {
    select,
    initialData: { clinics: {} },
    enabled:
      !!tenantId &&
      !isMutating &&
      (options?.enabled !== undefined ? options.enabled : true),
    refetchOnMount: true,
    ...options,
  });
};

export const useClinicsList = (enabled?: boolean) =>
  useSelect((data) => Object.values(data.clinics), { enabled });
