import { Box, Button, Typography } from "@mui/material";
import { ClinicStatus, PhoneNumberConnectionStatus } from "src/libs/models";
import React, { useEffect, useState } from "react";

import { StatusIndicator } from "./StatusIndicator";
import { calculateTimeAgo } from "src/libs/misc";
import { useClinicsList } from "./queries/useClinicsList";
import { useTestPMSConnectionMutation } from "./mutations/useTestPMSConnectionMutation";
import { useTestPhoneNumberConnectionMutation } from "./mutations/useTestPhoneNumberConnectionMutation";

const PhoneNumberTesting = ({
  clinicId,
  clinicStatus,
  phoneNumberIntegrationDetails,
  hasBaseClinicNumber,
}) => {
  const testPhoneNumberConnectionMutation =
    useTestPhoneNumberConnectionMutation();
  const { refetch } = useClinicsList(false);

  const [, setPollCount] = useState(0);
  const [isPolling, setIsPolling] = useState(false);

  const maxPollCount = 20;
  const pollingInterval = 2000;

  useEffect(() => {
    let intervalId: any;

    // const checkAndTerminatePolling = (count: number) => {
    //   console.log("polling count", count, isPolling, phoneNumberStatus);
    //   if (
    //     phoneNumberStatus === PhoneNumberConnectionStatus.CONNECTED &&
    //     isPolling &&
    //     count >= 2
    //   ) {
    //     setIsPolling(false);
    //     clearInterval(intervalId);

    //     console.log("polling terminated");
    //   }
    // };

    if (isPolling) {
      intervalId = setInterval(() => {
        setPollCount((prevCount) => {
          if (prevCount < maxPollCount) {
            refetch();
            // checkAndTerminatePolling(prevCount);
            console.info("polling");
            return prevCount + 1;
          } else {
            setIsPolling(false);
            clearInterval(intervalId);
            return prevCount;
          }
        });
      }, pollingInterval);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPolling, refetch, maxPollCount, pollingInterval]);

  const startPolling = () => {
    setPollCount(0);
    setIsPolling(true);
  };

  const testPhoneNumber = async () => {
    await testPhoneNumberConnectionMutation.mutateAsync({
      clinicId,
      baseClinicNumber: phoneNumberIntegrationDetails.baseClinicNumber,
    });
    startPolling();
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <StatusIndicator
          status={phoneNumberIntegrationDetails.phoneNumberStatus}
          polling={isPolling}
          type="Phone Number"
        />
        {phoneNumberIntegrationDetails.lastTestedTimestamp && (
          <Typography sx={{ fontSize: "13px", marginLeft: "5px" }}>
            (Last tested{" "}
            {calculateTimeAgo(
              phoneNumberIntegrationDetails.lastTestedTimestamp
            )}
            )
          </Typography>
        )}
      </Box>

      <Button
        variant="contained"
        onClick={testPhoneNumber}
        disabled={
          isPolling ||
          clinicStatus === ClinicStatus.ACTIVE ||
          !hasBaseClinicNumber
        }
        sx={{
          width: "120px",
          p: 1,
          fontSize: "10px",
          marginRight: "5px",
          marginBottom: "5px",
        }}
      >
        {isPolling ? "Testing..." : "Test Connection"}
      </Button>
    </Box>
  );
};

export default PhoneNumberTesting;
