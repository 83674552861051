import { Box, Button, Icon } from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Conversation, Voicemail } from "src/libs/models";
import React, { useEffect, useState } from "react";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Line } from "react-chartjs-2";
import { useConversations } from "../conversations/queries/useConversations";
import { useDefaultClinicId } from "../profile/queries/useUser";
import { useVoicemails } from "../voicemail/queries/useVoicemails";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const Usage = () => {
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toISOString().slice(0, 7)
  );

  const [missedCallsData, setMissedCallsData] = useState({
    labels: [],
    datasets: [
      {
        label: "Conversations (mobile)",
        data: [],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        pointBorderColor: "rgba(75,192,192,1)",
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        tension: 0.1,
      },
      {
        label: "Voicemails (landline)",
        data: [],
        fill: true,
        backgroundColor: "rgba(255,99,132,0.2)", // Soft pink for background
        borderColor: "rgba(255,99,132,1)", // Solid pink for borders
        pointBorderColor: "rgba(255,99,132,1)", // Same pink for points border
        pointHoverBackgroundColor: "rgba(255,99,132,1)", // Same pink for point hover background
        pointHoverBorderColor: "rgba(255,255,255,1)", // White for point hover border
        tension: 0.1,
      },
    ],
  });

  const conversations: Conversation[] = useConversations() || [];
  const voicemails: Voicemail[] = useVoicemails() || [];
  const defaultClinicId = useDefaultClinicId();

  useEffect(() => {
    // Function to get a date string in 'YYYY-MM' format for the local timezone
    const toLocalISOString = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, add 1 to get correct month
      return `${year}-${month}`;
    };
    const filteredConversations = conversations.filter(
      (conversation: Conversation) => {
        if (
          !(
            defaultClinicId === "ALL" ||
            conversation.clinicId === defaultClinicId
          )
        ) {
          return false;
        }

        const conversationDate = new Date(conversation.createTimestamp);
        const month = toLocalISOString(conversationDate);
        return month === selectedMonth;
      }
    );

    const filteredVoicemails = voicemails.filter((voicemail: Voicemail) => {
      if (
        !(defaultClinicId === "ALL" || voicemail.clinicId === defaultClinicId)
      ) {
        return false;
      }

      const voicemailDate = new Date(voicemail.createTimestamp);
      const month = toLocalISOString(voicemailDate);
      return month === selectedMonth;
    });

    const conversationsData = getMissedCallsByDay(filteredConversations);
    const voicemailsData = getMissedCallsByDay(filteredVoicemails);

    const labels = new Array(conversationsData.length)
      .fill(null)
      .map((_, i) => i + 1);

    setMissedCallsData({
      labels,
      datasets: [
        {
          label: "Conversations (mobile)",
          data: conversationsData,
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
          pointBorderColor: "rgba(75,192,192,1)",
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          tension: 0.1,
        },
        {
          label: "Voicemails (landline)",
          data: voicemailsData, // assuming data variable holds the dataset
          fill: true,
          backgroundColor: "rgba(255,99,132,0.2)", // Soft pink for background
          borderColor: "rgba(255,99,132,1)", // Solid pink for borders
          pointBorderColor: "rgba(255,99,132,1)", // Same pink for points border
          pointHoverBackgroundColor: "rgba(255,99,132,1)", // Same pink for point hover background
          pointHoverBorderColor: "rgba(255,255,255,1)", // White for point hover border
          tension: 0.1,
        },
      ],
    });
  }, [conversations, voicemails, selectedMonth]);

  const getMissedCallsByDay = (items: Conversation[] | Voicemail[]) => {
    if (!items.length) {
      return [];
    }

    // Assuming all items are in the same month and year
    const exampleDate = new Date(items[0].createTimestamp);
    const year = exampleDate.getFullYear();
    const month = exampleDate.getMonth(); // Note: month is 0-indexed

    // Get the current date for comparison
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate(); // Current day in the month

    // Check if the items are in the current month and year
    const isCurrentMonthAndYear =
      year === currentYear && month === currentMonth;

    // Get the number of days in the month
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    // Initialize counts array with either 0 or undefined based on the current day
    let counts = new Array(daysInMonth)
      .fill(null)
      .map((_, index) =>
        isCurrentMonthAndYear && index >= currentDay ? undefined : 0
      );

    items.forEach((item: Conversation | Voicemail) => {
      const localDate = new Date(item.createTimestamp);
      const dayIndex = localDate.getDate() - 1; // Adjust the day index (1-indexed to 0-indexed)

      // Only increment count if the day is in the past or present
      if (!isCurrentMonthAndYear || dayIndex < currentDay) {
        counts[dayIndex]++;
      }
    });

    return counts;
  };

  const formatSelectedMonth = (month) => {
    const date = new Date(`${month}-01T00:00:00`);
    const formatter = new Intl.DateTimeFormat("en", {
      month: "long",
      year: "numeric",
    });
    return formatter.format(date);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box sx={{ width: "70%", marginTop: "50px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Box
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: "10%",
              display: "flex",
              alignItems: "center",
              padding: "7px",
              marginRight: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedMonth((prevMonth) => {
                  const year = parseInt(prevMonth.substring(0, 4));
                  const month = parseInt(prevMonth.substring(5, 7));
                  const newYear = month === 1 ? year - 1 : year;
                  const newMonth = month === 1 ? 12 : month - 1;
                  return `${newYear}-${newMonth.toString().padStart(2, "0")}`;
                });
              }}
            >
              <ArrowBackIosIcon />
            </Box>

            <Box sx={{ marginX: "20px" }}>
              {formatSelectedMonth(selectedMonth)}
            </Box>

            <Box
              sx={{
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() =>
                setSelectedMonth((prevMonth) => {
                  const year = parseInt(prevMonth.substring(0, 4));
                  const month = parseInt(prevMonth.substring(5, 7));
                  const newYear = month === 12 ? year + 1 : year;
                  const newMonth = month === 12 ? 1 : month + 1;
                  return `${newYear}-${newMonth.toString().padStart(2, "0")}`;
                })
              }
            >
              <ArrowForwardIosIcon />
            </Box>
          </Box>
        </Box>

        <Box>
          <Line data={missedCallsData} />
        </Box>
      </Box>
    </Box>
  );
};

export default Usage;
