import { Button, TableCell } from "@mui/material";
import React, { useEffect, useState } from "react";

interface Props {
  children: React.ReactNode;
  sx?: object;
}

const ScrollableTableCell: React.FunctionComponent<Props> = ({
  children,
  sx,
}) => {
  const scrollRef = React.useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  const handleScroll = (direction: "left" | "right") => {
    if (scrollRef.current) {
      const { current } = scrollRef;
      const scrollAmount = direction === "left" ? -150 : 150;
      current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const checkScroll = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    const currentRef = scrollRef.current;
    currentRef?.addEventListener("scroll", checkScroll);

    // Initial check in case the content is already overflowing when mounted
    checkScroll();

    return () => currentRef?.removeEventListener("scroll", checkScroll);
  }, []);

  return (
    <TableCell
      sx={{
        overflow: "hidden",
        position: "relative",
        ...sx,
      }}
    >
      {showLeftButton && (
        <Button
          sx={{
            position: "absolute",
            left: 0,
            top: "0",
            zIndex: 1,
            height: "100%",
            backgroundColor: "transparent",
            border: "none",
            "&:hover": {
              backgroundColor: "rgba(173, 216, 230, 0.3)",
              border: "none",
            },
          }}
          variant="outlined"
          onClick={() => handleScroll("left")}
        ></Button>
      )}
      <div
        ref={scrollRef}
        style={{
          overflowX: "auto",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {children}
      </div>
      {showRightButton && (
        <Button
          sx={{
            position: "absolute",
            right: 0,
            top: "0",
            zIndex: 1,
            height: "100%",
            backgroundColor: "transparent",
            border: "none",
            "&:hover": {
              backgroundColor: "rgba(173, 216, 230, 0.3)",
              border: "none",
            },
          }}
          variant="outlined"
          onClick={() => handleScroll("right")}
        ></Button>
      )}
    </TableCell>
  );
};

export default ScrollableTableCell;
