import * as React from "react";

import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  Callback,
  CallbackState,
  Clinic,
  Voicemail,
  VoicemailState,
} from "src/libs/models";
import { formatDate, formatPhoneNumber, truncateText } from "src/libs/misc";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Audio from "./Audio";
import EmptyState from "src/components/EmptyState";
import FilterListIcon from "@mui/icons-material/FilterList";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { debounce } from "lodash";
import { keyframes } from "@emotion/react";
import { useClinicsList } from "../clinics/queries/useClinicsList";
import { useDefaultClinicId } from "../profile/queries/useUser";
import { useNavigate } from "react-router-dom";
import { useUpdateVoicemailsMutation } from "./mutations/useUpdateVoicemailsMutation";
import { useVoicemails } from "./queries/useVoicemails";

// Define a mapping for state colors based on your CallbackState enum
const stateColors = {
  NEW: "#FF9800", // Orange for waiting or in-process actions
  REVIEWED: "#03A9F4", // Light green for success and completion
  COMPLETED: "#8BC34A", // Grey for neutral, canceled actions
  ARCHIVED: "#9E9E9E", // Light blue for attempts that indicate a need for further action
};

const additionalInfoColors = {
  patientFullName: "#ADD8E6", // Light Blue
  patientDateOfBirth: "#CAB8FF", // Lavender
  patientEmail: "#B39DDB", // Lavender, same as patientDOB for consistency
  entryTimestamp: "#FFD700", // Gold
};

const VoicemailsTable = () => {
  // basic state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [stateAnchorEl, setStateAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const defaultClinicId = useDefaultClinicId();
  // filtering Info
  const [stateFilter, setStateFilter] = useState<VoicemailState[]>(
    Object.values(VoicemailState)
  );

  const clinicIds = (useClinicsList().data || []).map(
    (clinic: Clinic) => clinic.id
  );
  const [noteEdits, setNoteEdits] = useState<{ [key: string]: string }>({});

  const clinicIdsInitialized = useRef(false);
  const [sortDirection, setSortDirection] = useState("desc"); // "asc" | "desc"
  const updateVoicemailsMutation = useUpdateVoicemailsMutation();

  const voicemails: Voicemail[] = useVoicemails() || [];

  const filteredVoicemails = voicemails.filter((voicemail: Voicemail) => {
    return (
      stateFilter.includes(voicemail.state) &&
      (defaultClinicId === "ALL" || defaultClinicId === voicemail.clinicId)
    );
  });

  const sortedVoicemails = [...filteredVoicemails].sort((a, b) => {
    const dateA = new Date(a.createTimestamp);
    const dateB = new Date(b.createTimestamp);

    return sortDirection === "asc"
      ? dateA.getTime() - dateB.getTime()
      : dateB.getTime() - dateA.getTime();
  });
  const initialized = useRef(false);

  useEffect(() => {
    if (voicemails && !initialized.current) {
      const initialNotes = voicemails.reduce((acc, voicemail: Voicemail) => {
        acc[voicemail.voicemailId] = voicemail.notes;
        return acc;
      }, {});
      setNoteEdits(initialNotes);
      initialized.current = true;
    }
  }, [voicemails]);

  // Debounced function to simulate saving notes to a server or database
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveNotes = useCallback(
    debounce(async (voicemailId: string, notes: string) => {
      console.log(`Saving note for voicemailId ${voicemailId}: ${notes}`);

      await updateVoicemailsMutation.mutateAsync({
        voicemailId,
        notes,
      });
    }, 1000),
    []
  ); // 1000 ms debounce time

  const handleNoteInputChange = (voicemailId: string, newNote: string) => {
    setNoteEdits((prevNotes) => ({
      ...prevNotes,
      [voicemailId]: newNote,
    }));
  };

  // debounce and save locally
  const handleNoteEdit = (callbackId: string, newNote: string) => {
    handleNoteInputChange(callbackId, newNote);
    saveNotes(callbackId, newNote);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleStatusMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setStateAnchorEl(event.currentTarget);
  };

  // Handle selecting a filter option
  const handleStatusFilterChange = (status: VoicemailState) => {
    setStateFilter((currentFilters) => {
      const isStatusFiltered = currentFilters.includes(status);
      if (isStatusFiltered) {
        return currentFilters.filter((s) => s !== status);
      } else {
        return [...currentFilters, status];
      }
    });
  };

  const updateVoicemail = async (
    voicemailId: string,
    state: VoicemailState
  ): Promise<void> => {
    await updateVoicemailsMutation.mutateAsync({
      voicemailId,
      state,
    });
  };

  const handleVoicemailChange = async (
    voicemailId: string,
    state: VoicemailState
  ): Promise<void> => {
    await updateVoicemail(voicemailId, state);
  };

  if (voicemails.length === 0) {
    return <EmptyState text="No Voicemails found" sx={{ marginTop: "30px" }} />;
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                key={"1"}
                align="left"
                style={{ maxWidth: "35px", minWidth: "35px" }}
              >
                Patient Number
              </TableCell>
              {/* <TableCell key={"2"} align="left" style={{ maxWidth: "120px" }}>
                Transcription
              </TableCell> */}
              <TableCell key={"2"} align="left" style={{ maxWidth: "75px" }}>
                Voicemail Summary
              </TableCell>
              <TableCell key={"3"} align="left" style={{ maxWidth: "60px" }}>
                Context
              </TableCell>
              <TableCell key={"4"} align="right" style={{ maxWidth: "15px" }}>
                Status
                <IconButton
                  aria-label="filter list"
                  onClick={handleStatusMenuClick}
                  size="small"
                >
                  <FilterListIcon />
                </IconButton>
                <Menu
                  anchorEl={stateAnchorEl}
                  open={Boolean(stateAnchorEl)}
                  onClose={() => setStateAnchorEl(null)}
                >
                  {Object.values(VoicemailState).map((status) => (
                    <MenuItem
                      key={status}
                      onClick={() => handleStatusFilterChange(status)}
                    >
                      <Checkbox checked={stateFilter.indexOf(status) > -1} />
                      <ListItemText primary={status} />
                    </MenuItem>
                  ))}
                </Menu>
              </TableCell>
              <TableCell key={"5"} align="right" style={{ maxWidth: "20px" }}>
                Last updated
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedVoicemails
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((voicemail: Voicemail) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={voicemail.voicemailId}
                    sx={{ height: "90px" }}
                  >
                    <TableCell
                      key={"1"}
                      align="left"
                      sx={{ maxWidth: "35px", minWidth: "35px" }}
                    >
                      {formatPhoneNumber(voicemail.patientNumber)}
                    </TableCell>
                    {/* <TableCell
                      key={"2"}
                      align="left"
                      sx={{
                        maxWidth: "120px",
                      }}
                    >
                      {voicemail.recording.transcription}
                    </TableCell> */}

                    <TableCell key={"2"} align="left" sx={{ maxWidth: "75px" }}>
                      <Box>
                        {voicemail.recording.transcription ? (
                          <Box>
                            <Box component="span" sx={{ fontWeight: "700" }}>
                              Transcription
                            </Box>
                            : {voicemail.recording.transcription}
                          </Box>
                        ) : (
                          <Box sx={{ fontWeight: "800" }}>
                            No transcription available (audio too short or
                            inaudible)
                          </Box>
                        )}
                      </Box>
                      <Box>
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={noteEdits[voicemail.voicemailId] || ""}
                          onChange={(e) =>
                            handleNoteEdit(
                              voicemail.voicemailId,
                              e.target.value
                            )
                          }
                          placeholder="Edit Notes"
                          InputProps={{
                            style: { fontSize: "0.875rem" }, // Smaller text size for better table fitting
                          }}
                          margin="dense" // Reduces the space used by the TextField
                          multiline // Enables multiline input
                          minRows={2} // Minimum number of rows to display (can be adjusted as needed)
                          maxRows={6} // Maximum number of rows before scrollbar appears (optional)
                        />
                      </Box>
                    </TableCell>
                    <TableCell key={"3"} align="left" sx={{ maxWidth: "60px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                          fontSize: "0.75rem",
                        }}
                      >
                        <Box>
                          <Audio src={voicemail.recording.signedURL} />
                        </Box>
                        {voicemail.createTimestamp && (
                          <Chip
                            label={`entry timestamp: ${formatDate(
                              voicemail.createTimestamp
                            )}`}
                            size="small"
                            sx={{
                              backgroundColor:
                                additionalInfoColors.entryTimestamp,
                            }}
                            key={"createTimestamp"}
                          />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell
                      key={"4"}
                      align="right"
                      sx={{
                        maxWidth: "15px",
                      }}
                    >
                      <Select
                        labelId="role-select-label"
                        id="role-select"
                        value={voicemail.state}
                        onChange={(event) =>
                          handleVoicemailChange(
                            voicemail.voicemailId,
                            event.target.value as VoicemailState
                          )
                        }
                        sx={{
                          height: "30px",
                          backgroundColor: stateColors[voicemail.state],
                          color: "black",
                        }}
                      >
                        <MenuItem value={VoicemailState.NEW}>NEW</MenuItem>
                        <MenuItem value={VoicemailState.REVIEWED}>
                          REVIEWED
                        </MenuItem>
                        <MenuItem value={VoicemailState.COMPLETED}>
                          COMPLETED
                        </MenuItem>
                        <MenuItem value={VoicemailState.ARCHIVED}>
                          ARCHIVED
                        </MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell
                      key={"5"}
                      align="right"
                      sx={{ maxWidth: "20px" }}
                    >
                      {formatDate(voicemail.editTimestamp)}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={sortedVoicemails.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default VoicemailsTable;
