import { Box } from "@mui/material";
import React from "react";
import VoicemailsTable from "./VoicemailsTable";

const Voicemails = () => {
  return (
    <Box>
      <VoicemailsTable />
    </Box>
  );
};

export default Voicemails;
