import { Callback, CallbackState, ClinicStatus } from "src/libs/models";

import { RefreshReturn } from "../queries/useCallbacks";
import { authedPost } from "src/libs/aws";
import { produce } from "immer";
import { useOptimisticUpdate } from "src/libs/query";
import { useTenantId } from "src/pages/tenant/queries";

interface UpdateCallbackProps {
  callbackId: string;
  state?: CallbackState;
  notes?: string;
}

const updateCallbackAction =
  (tenantId: string) =>
  async ({ state, notes, callbackId }: UpdateCallbackProps): Promise<any> => {
    return authedPost("/callbacks", {
      action: "update",
      tenantId,
      callbackId,
      state,
      notes,
    });
  };

export const useUpdateCallbackMutation = () => {
  const tenantId: string = useTenantId();

  return useOptimisticUpdate(
    ["callbacks", tenantId],
    updateCallbackAction(tenantId),
    ({ state, notes, callbackId }) =>
      produce((draftCallbacks: RefreshReturn) => {
        const callback = draftCallbacks.callbacks.find(
          (callback: Callback) => callback.callbackId === callbackId
        );

        const timestamp = new Date().toISOString();
        if (callback) {
          callback.state = state || callback.state;
          callback.notes = notes || callback.notes;
          callback.editTimestamp = timestamp;
        }
      }),
    {
      retry: 3,
      mutationKey: ["clinics", tenantId],
    }
  );
};
