export const homePage = "/conversations";

export const stagingHostnames = ["staging.decvr4p4xlzj7.amplifyapp.com"];
export const prodHostnames = [
  "prod.d1jsn41o7qu8dh.amplifyapp.com",
  "usecaptureai.com",
  "www.usecaptureai.com",
];

export const defaultBeginTime = "09:00";
export const defaultEndTime = "16:00";
