import { Avatar, Box, Typography } from "@mui/material";
import React, { useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AndroidIcon from "@mui/icons-material/Android";
import { formatDate } from "src/libs/misc";

const ChatMessage = ({ message, isCurrentUser, messageTime }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isCurrentUser ? "row-reverse" : "row",
        margin: "10px",
        "&:hover .message-time": {
          display: "block", // Show the message time on hover
        },
      }}
    >
      <Avatar
        sx={{
          marginLeft: isCurrentUser ? "15px" : "12px",
          marginRight: isCurrentUser ? "12px" : "15px",
          bgcolor: isCurrentUser ? "#455a64" : "#c3c3c4", // Example colors: blue for current user, grey for others
        }}
      >
        {isCurrentUser ? (
          <AndroidIcon sx={{ color: "white" }} />
        ) : (
          <AccountCircleIcon sx={{ color: "white" }} />
        )}
      </Avatar>

      <Box
        sx={{
          maxWidth: "70%",
          backgroundColor: isCurrentUser ? "#6b36ff" : "#f3f3f4", // Lighter tones for chat bubbles
          color: isCurrentUser ? "white" : "black",
          padding: "15px",
          borderRadius: "25px",
          display: "flex",
          flexDirection: "column",
          wordBreak: "break-word", // Ensure long words do not overflow
          position: "relative", // Needed for absolute positioning of the time
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box>{message.content}</Box>
        <Box
          sx={{
            display: isHovered ? "block" : "none",
            color: isCurrentUser ? "white" : "black",
            fontSize: "12px",
          }}
        >
          {formatDate(messageTime)}
        </Box>
      </Box>
    </Box>
  );
};

export default ChatMessage;
