import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useEffect, useState } from "react";

export function useOptimisticUpdate<V = unknown, Return = unknown>(
  queryKey: QueryKey,
  action: MutationFunction<unknown, V>,
  update: (variables: V) => any,
  options?: Pick<UseMutationOptions, "retry" | "retryDelay" | "mutationKey">,
  dontInvalidate?: boolean
) {
  const queryClient = useQueryClient();

  return useMutation(action, {
    onMutate: async (args: V) => {
      // split resolve from variables only when the data type is an object
      let variables = args;
      const resolve = (args as any)?.resolve;
      if (resolve) {
        const { resolve, ...vars } = args as any;
        variables = vars;
      }

      // cancel outgoing refetches (so they don't overwrite the optimistic update)
      await queryClient.cancelQueries(queryKey);
      const snapshot = queryClient.getQueryData<Return>(queryKey);
      queryClient.setQueryData<Return>(queryKey, update(variables));

      // optionally signal when query data is updated
      if (resolve) resolve();

      return { snapshot };
    },
    onError: (err, _, context) => {
      if (err instanceof Error) {
        console.error(err.message);
      } else {
        console.error(err);
      }
      queryClient.setQueryData(queryKey, context.snapshot);
    },
    onSettled: () => {
      !dontInvalidate && queryClient.invalidateQueries(queryKey);
    },
    ...options,
  });
}

interface UsePollingQueryOptions extends UseQueryOptions {
  refetchInterval?: number;
  maxPollCount?: number;
}

// const usePollingQuery = <TQueryFnData, TData>(
//   queryKey: QueryKey,
//   queryFn: QueryFunction<TQueryFnData, QueryKey>,
//   options: UsePollingQueryOptions = {}
// ) => {
//   const [pollCount, setPollCount] = useState(0);
//   const maxPollCount = options?.maxPollCount || 10;
//   const refetchInterval = options?.refetchInterval || 0;
//   const [hasReachedMaxPolls, setHasReachedMaxPolls] = useState(false);

//   const { data, refetch } = useQuery(queryKey, queryFn, {
//     ...options,
//     refetchInterval: false, // Disable automatic refetchInterval from React Query
//   });

//   useEffect(() => {
//     let intervalId;
//     if (refetchInterval && pollCount < maxPollCount) {
//       intervalId = setInterval(() => {
//         refetch();
//         setPollCount((prevCount) => {
//           const newCount = prevCount + 1;
//           if (newCount >= maxPollCount) {
//             setHasReachedMaxPolls(true);
//           }
//           return newCount;
//         });
//       }, refetchInterval);
//     }

//     return () => clearInterval(intervalId);
//   }, [refetch, pollCount, refetchInterval, maxPollCount]);

//   return { data: data as TQueryFnData, hasReachedMaxPolls };
// };
